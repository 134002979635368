import React, { useEffect, useState } from 'react'
import classNames from 'classnames/bind'

import Globe from './GlobeCanvas'
import * as s from './FullscreenGlobe.module.scss'

const cn = classNames.bind(s)

const FullscreenGlobe = () => {
  const [showGlobe, setShowGlobe] = useState<boolean>(false)

  // Delay globe creation for smoother page transition
  useEffect(() => {
    setTimeout(() => setShowGlobe(true), 300) // number fishing
  }, [])

  return (
    <div className={cn('globeContainer')}>
      {showGlobe && <Globe className={cn('globe')} showCountries={false} fullWindowParallax lng={-100} lat={60} />}
    </div>
  )
}

export default FullscreenGlobe
