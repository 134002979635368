import React, { useState } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import classNames from 'classnames/bind'

import { linkResolver } from 'prismic/linkResolver'
import Spacer from 'components/core/Spacer'
import { Column } from 'components/core/Grid'
import ViewportEnter from 'components/core/ViewportEnter'
import { MonumentSubtitle, MonumentSteps, CircularSteps } from 'components/ui/Text'
import { PrimaryButton } from 'components/ui/Buttons'

import { StepProps } from './StepsTypes'
import * as s from './Steps.module.scss'
const cn = classNames.bind(s)

const Step = ({ item, index }: { item: StepProps; index: number }) => {
  const [isInView, setIsInView] = useState<boolean>(false)
  return (
    <Column initial={6} medium={2} className={cn('innerColumn', { isInView })}>
      <ViewportEnter onEnter={() => setIsInView(true)}>
        <div className={cn('card')}>
          <Spacer initial='9.6vw' medium='1.88vw' />
          <MonumentSubtitle as='h4'>{index + 1}</MonumentSubtitle>
          <Spacer initial='5.4vw' medium='1.2vw' />
          {item?.image?.gatsbyImageData && (
            <GatsbyImage
              objectFit='contain'
              className={cn('image')}
              image={item.image.gatsbyImageData}
              alt={item?.image?.alt || ''}
              key={index}
            />
          )}
          <Spacer initial='5.4vw' medium='1.5vw' />
          <MonumentSteps className={cn('sub')} as='h3'>
            {item.step_title?.text}
          </MonumentSteps>
          <CircularSteps>{item.step_description?.text}</CircularSteps>
          <Spacer initial='6.3vw' medium='1.78vw' />
          {item.step_link && (
            <div className={cn('cta')}>
              <PrimaryButton classNameLabel={cn('link')} to={linkResolver(item.step_link)}>
                {item.step_link_label || ''}
              </PrimaryButton>
            </div>
          )}
        </div>
      </ViewportEnter>
    </Column>
  )
}

export default Step
