import React from 'react'
import classNames from 'classnames/bind'
import { motion, useTransform } from 'framer-motion'
const cn = classNames.bind(s)

import { TrailItemProps } from './TrailingTextTypes'
import * as s from './TrailingText.module.scss'

const DELAY_FACTOR = 0.04
const START_VALUE = 0
const END_VALUE = 0.8

export const getStopY = (len: number, index: number) => (len - index) * -0.5 * 15

const TrailItem = ({ motionValue, index, len, children }: TrailItemProps) => {
  const first = index === 0
  const inStopValue = END_VALUE - DELAY_FACTOR * index

  const startY = (len - index) * -0.5 * 33
  const stopY = getStopY(len, index)
  const y = useTransform(motionValue, [START_VALUE, inStopValue], [startY, stopY])

  return (
    <motion.span className={cn('parallax')} style={{ zIndex: index * -1, y }}>
      <span className={cn({ first, sibling: !first })} aria-hidden='true'>
        {children}
      </span>
    </motion.span>
  )
}

export default TrailItem
