import React, { forwardRef, ForwardedRef } from 'react'

import InlineMapSVG from './WorldMap.react.svg'

/**
 * This is an inlined SVG map that we hide visually.
 * It is used to generate a WebGL texture programmatically
 */
export default forwardRef(function MapSVG(
  { showCountries }: { showCountries: boolean },
  ref: ForwardedRef<HTMLDivElement>,
) {
  return (
    <div ref={ref} style={{ position: 'absolute', opacity: 0, zIndex: -1 }}>
      {showCountries && <InlineMapSVG style={{ width: '100%' }} />}
    </div>
  )
})
