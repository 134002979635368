import React, { useState } from 'react'
import classNames from 'classnames/bind'

import useSourcesContext from 'context/sources'
import useCopyContext from 'context/copy'
import useIsDesktop from 'lib/useIsDesktop'
import { Row, Column } from 'components/core/Grid'
import Container from 'components/core/Container'
import Interactable from 'components/core/Interactable'
import { CircularHeading2, CircularBody } from 'components/ui/Text'

import TakeawayPopup from './TakeawayPopup'
import TakeawayIllustrations from './TakeawayIllustrations'
import StickyReactions from './StickyReactions'
import { TakeawayProps } from './TakeawayTypes'
import * as s from './Takeaway.module.scss'

const cn = classNames.bind(s)
const largeBreakpoint = parseInt(s.breakpointDesktop)

const Takeaway = ({ data, items }: TakeawayProps) => {
  const isDesktop = useIsDesktop(largeBreakpoint)

  const [open, setOpen] = useState(false)
  const sources = useSourcesContext(s => s.sources)
  const copy = useCopyContext(s => s.copy)

  if (!data.text) return null

  return (
    <div className={cn('relative')}>
      {isDesktop && <TakeawayIllustrations items={items} position='sticky' />}
      <div className={cn('container')}>
        <Container className={cn('inner')}>
          <Row>
            <Column initial={0} medium={1} />
            <Column initial={6} medium={4}>
              <div className={cn('textContainer')}>
                <CircularHeading2 className={cn('title')} as='p'>
                  {data.text.text}
                </CircularHeading2>
                {!isDesktop && <TakeawayIllustrations items={items} position='absolute' />}
              </div>
              {/* LINK TO SOURCES */}
              {sources.length && sources[0].source_label && (
                <div className={cn('sourcesContainer')}>
                  <Interactable
                    className={cn('sourcesLink')}
                    onClick={() => setOpen(true)}
                    onEnter={() => setOpen(true)}
                    onLeave={() => setOpen(false)}
                  >
                    <CircularBody
                      className={cn('sources')}
                      aria-label={copy.sources_title}
                    >{`${copy.sources} +`}</CircularBody>
                    <TakeawayPopup
                      open={open && sources.length ? true : false}
                      onClose={() => setOpen(false)}
                      copy={copy}
                      sources={sources}
                    />
                  </Interactable>
                </div>
              )}
            </Column>
            <Column initial={0} medium={1} />
          </Row>
        </Container>
      </div>
      <StickyReactions />
    </div>
  )
}

export default Takeaway
