// extracted by mini-css-extract-plugin
export var colorBlack = "#000";
export var colorGray = "gray";
export var colorDarkGray = "#262626";
export var colorLightGray = "#e5e5e5";
export var colorWhite = "#fff";
export var colorElectricBlue = "#0000fe";
export var colorPastelBlue = "#bbe5fe";
export var colorCandyPink = "#f9709a";
export var colorPastelPink = "#ffcdd2";
export var colorDefaultLime = "#cef564";
export var colorPastelLime = "#eeffbe";
export var colorBlueLagoon = "#9cf0e1";
export var colorPastelLagoon = "#ceffec";
export var colorDefaultLavender = "#8738f6";
export var colorPastelLavender = "#eadcff";
export var colorBrightYellow = "#ffe500";
export var colorDefaultGreen = "#12b968";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1024px";
export var breakpointLarge = "1440px";
export var colCount = "6";
export var colWidth = "16.6666666667%";
export var container = "DataPieChart-module--container--1Rm0O";
export var content = "DataPieChart-module--content--285zs";
export var text = "DataPieChart-module--text--2A--A";
export var textContent = "DataPieChart-module--textContent--UINZm";
export var extended = "DataPieChart-module--extended--GONy8";
export var pieContainer = "DataPieChart-module--pieContainer--3a44R";
export var label = "DataPieChart-module--label--1bN60";
export var pie = "DataPieChart-module--pie--2ryPF";
export var first = "DataPieChart-module--first--S1yaB";
export var border = "DataPieChart-module--border--3DTY_";
export var shadow = "DataPieChart-module--shadow--2UUrf";
export var reference = "DataPieChart-module--reference--2p_f8";
export var rightAlignData = "DataPieChart-module--rightAlignData--37Qrg";
export var popupContainer = "DataPieChart-module--popupContainer--24A7i";
export var left = "DataPieChart-module--left--25b7p";
export var top = "DataPieChart-module--top--3iFSP";
export var popup = "DataPieChart-module--popup--LcHav";
export var srOnly = "DataPieChart-module--srOnly--3JyS9";