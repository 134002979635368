import React, { useCallback, useMemo, useState } from 'react'
import classNames from 'classnames/bind'
import { graphql, useStaticQuery } from 'gatsby'
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'
import { ref, update, increment } from 'firebase/database'
import { useLocation } from '@reach/router'

import { useDatabase, useUser } from 'lib/firebase'
import numberFormatter from 'lib/numberFormatter'
import useFirebaseContext from 'context/firebase'
import useUIContext from 'context/ui'
import getLocalizedData from 'lib/getLocalizedData'

import { ReactionsProps, ExternalDataProps, LabelsProps } from './ReactionsTypes'
import items from './Items'
import Reaction from './Reaction'
import * as s from './Reactions.module.scss'
const cn = classNames.bind(s)

const reactionsQuery = graphql`
  query reactionsQuery {
    allPrismicSiteSettings {
      edges {
        node {
          _previewable
          lang
          data {
            love
            sad
            empowered
            hopeful
          }
        }
      }
    }
  }
`

const Reactions = ({ className }: ReactionsProps) => {
  const [activeStateEnabled, setActiveStateEnabled] = useState<boolean>(true)

  const { pathname } = useLocation()
  const staticData = useStaticQuery(reactionsQuery)
  const activeFactFirebaseId = useUIContext(s => s.activeFactFirebaseId)
  const { data: content }: ExternalDataProps = useMergePrismicPreviewData(staticData)

  const prismicSiteSettings = getLocalizedData(pathname, content?.allPrismicSiteSettings)

  const labels: LabelsProps = useMemo(() => prismicSiteSettings?.data || {}, [prismicSiteSettings?.data])

  /* Firebase */
  const database = useDatabase()
  const user = useUser()
  const userReactions = useFirebaseContext(s => s.userReactions)
  const setUserReactionCount = useFirebaseContext(s => s.setUserReactionCount)
  const setUserReactions = useFirebaseContext(s => s.setUserReactions)
  const setTotalReactions = useFirebaseContext(s => s.setTotalReactions)
  const totalReactions = useFirebaseContext(s => s.totalReactions)

  const activeFactReactions = useMemo(() => {
    if (!totalReactions || !activeFactFirebaseId) return null
    return totalReactions[activeFactFirebaseId] || null
  }, [totalReactions, activeFactFirebaseId])

  const [formattedCount, charCountLength] = useMemo(() => {
    const obj = { ...activeFactReactions }
    let len = 0
    if (activeFactReactions) {
      for (const key in activeFactReactions) {
        const formatted = numberFormatter(activeFactReactions[key])
        if (formatted.length > len) len = formatted.length
        obj[key] = numberFormatter(activeFactReactions[key])
      }
    }

    return [obj, len]
  }, [activeFactReactions])

  const react = useCallback(
    (reaction: string) => {
      if (!database || !user || !activeFactFirebaseId) return

      const previousReaction = userReactions && userReactions[activeFactFirebaseId]
      if (reaction === previousReaction) return /* Exit early if no change is needed */

      setUserReactions({ ...userReactions, [activeFactFirebaseId]: reaction })
      const next = { ...activeFactReactions, [reaction]: (activeFactReactions?.[reaction] || 0) + 1 }
      if (previousReaction) {
        next[previousReaction] =
          activeFactReactions?.[previousReaction] && activeFactReactions?.[previousReaction] > 0
            ? next[previousReaction] - 1
            : 0
      }
      setTotalReactions({ ...totalReactions, [activeFactFirebaseId]: next })
      setUserReactionCount(1)
      update(ref(database), { [`requestedReaction/${activeFactFirebaseId}/${reaction}`]: increment(1) })
      setActiveStateEnabled(true)
    },
    [
      database,
      user,
      activeFactFirebaseId,
      userReactions,
      setUserReactions,
      setActiveStateEnabled,
      activeFactReactions,
      totalReactions,
      setTotalReactions,
      setUserReactionCount,
    ],
  )

  return (
    <div className={cn('wrapper', className)}>
      {items.map(item => (
        <Reaction
          key={item.id}
          item={item}
          label={labels[item.id]}
          totalReactions={formattedCount?.[item.id] || null}
          minCountContainerWidth={(charCountLength < 2 ? 2 : charCountLength) * 0.6}
          storedReaction={
            activeFactFirebaseId && activeStateEnabled ? userReactions?.[activeFactFirebaseId] === item.id : false
          }
          onClick={() => react(item.id)}
          interacting={(interacting: boolean) => setActiveStateEnabled(!interacting)}
        />
      ))}
    </div>
  )
}

export default Reactions
