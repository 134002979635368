import React from 'react'
import classNames from 'classnames/bind'
import { GatsbyImage } from 'gatsby-plugin-image'

import useUIContext from 'context/ui'
import { MonumentHeading1 } from 'components/ui/Text'
import { ParallaxLayers, ParallaxLayer } from 'components/ui/ParallaxLayers'
import getArtDirectedImage from 'lib/getArtDirectedImage'
import renderSpecialCharacters from 'lib/renderSpecialCharacters'

import { SparkSentenceFullScreenProps } from './SparkSentenceFullScreenTypes'
import * as s from './SparkSentenceFullScreen.module.scss'

const cn = classNames.bind(s)

const mediumBreakpoint = parseInt(s.breakpointMedium)

const FullScreen = ({ data }: SparkSentenceFullScreenProps) => {
  const foregroundImage = data.foreground_layer ? getArtDirectedImage(data.foreground_layer, mediumBreakpoint) : null
  const backgroundImage = data.background_layer ? getArtDirectedImage(data.background_layer, mediumBreakpoint) : null
  const decodedString = renderSpecialCharacters(data?.title_layer?.text || '')
  const ready = useUIContext(s => s.isFactPageScrollable)
  const alt = data?.foreground_layer?.alt

  return (
    <div className={cn('wrapper', { ready })}>
      <ParallaxLayers type='fullscreen'>
        {backgroundImage && (
          <ParallaxLayer intensity={ready ? 0.33 : 0} isAbsolute>
            <div className={cn('imageLayer')}>
              <GatsbyImage
                className={cn('image')}
                objectFit='contain'
                image={backgroundImage}
                alt={''}
                aria-hidden='true'
              />
            </div>
          </ParallaxLayer>
        )}
        <ParallaxLayer isText outlineColor={s.colorBlack} textColor={s.colorWhite} intensity={ready ? 0.6 : 0}>
          <MonumentHeading1 className={cn('titleLayer', { isNarrow: data.layout === 'Narrow' })}>
            {decodedString}
          </MonumentHeading1>
        </ParallaxLayer>
        {foregroundImage && (
          <ParallaxLayer intensity={ready ? 1.33 : 0} isAbsolute>
            <div className={cn('imageLayer')}>
              <GatsbyImage
                className={cn('image')}
                objectFit='contain'
                image={foregroundImage}
                alt={alt || ''}
                {...(!alt && { role: 'presentation' })}
              />
            </div>
          </ParallaxLayer>
        )}
      </ParallaxLayers>
    </div>
  )
}

export default FullScreen
