import React, { useState } from 'react'
import classNames from 'classnames/bind'
import { RichText } from 'prismic-reactjs'

import Container from 'components/core/Container'

import serializer from './serializer'
import { ShortExplanationOutlineProps } from './ShortExplanationOutlineTypes'
import * as s from './ShortExplanationOutline.module.scss'

const cn = classNames.bind(s)

const ShortExplanationOutline = ({ data, items }: ShortExplanationOutlineProps) => {
  const [active, setActive] = useState<number | null>(null)
  return (
    <Container className={cn('container')}>
      {data.text && <RichText render={data.text.raw} htmlSerializer={serializer(items, active, a => setActive(a))} />}
    </Container>
  )
}

export default ShortExplanationOutline
