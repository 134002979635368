import React, { useState, useMemo, useLayoutEffect, useCallback } from 'react'
import classNames from 'classnames/bind'
import { useWindowSize } from '@react-hook/window-size'
import { RichText } from 'prismic-reactjs'

import useUIContext from 'context/ui'
import { CircularHeading4 } from 'components/ui/Text'
import StickyItems from 'components/core/StickyItems'
import { Row, Column } from 'components/core/Grid'
import Container from 'components/core/Container'
import GlobeCanvas from 'components/ui/Globe'

import * as s from './Data3DGlobe.module.scss'
import { Data3DGlobeProps } from './Data3DGlobeTypes'
import htmlSerializer from './serializer'

const cn = classNames.bind(s)
const mediumBreakpoint = parseInt(s.breakpointMedium)

const Data3DGlobe = ({ data, items }: Data3DGlobeProps) => {
  const [width] = useWindowSize({ wait: 200 })
  const [isDesktop, setIsDesktop] = useState<boolean>(true)
  const [showGlobe, setShowGlobe] = useState<boolean>(false)
  const [globeCountries, setGlobeCountries] = useState('')
  const [globeCoordinates, setGlobeCoordinates] = useState({ longitude: 0, latitude: 0 })
  const isFactPageScrollable = useUIContext(s => s.isFactPageScrollable)

  useLayoutEffect(() => {
    setIsDesktop(width >= mediumBreakpoint)
    // Delay globe creation to have a smooth page transition
    if (isFactPageScrollable) setShowGlobe(true)
  }, [width, isFactPageScrollable])

  const itemsMobile = useMemo(() => {
    return items.map(i => ({ ...i, layout: data.layout }))
  }, [data.layout, items])

  const itemsDesktop = useMemo(
    () =>
      items.map(item => ({
        layout: data.layout.replace('2 Column ', ''),
        text: (
          <div className={cn('textContainer')}>
            {item.region_title?.text && (
              <CircularHeading4 className={cn('title')} as='h2'>
                {item.region_title.text}
              </CircularHeading4>
            )}
            {item.text?.text && (
              <div className={cn('text')}>
                <RichText render={item.text?.raw} htmlSerializer={htmlSerializer(true)} />
              </div>
            )}
          </div>
        ),
      })),
    [items, data.layout],
  )

  const updateGlobe = useCallback(
    (index: number) => {
      setTimeout(() => {
        if (items[index]) {
          setGlobeCountries(items[index].selected_countries)
          setGlobeCoordinates(items[index].globe_rotation)
        }
      })
    },
    [items],
  )

  if (!isDesktop) {
    return (
      <Container className={cn('container')}>
        {itemsMobile.map((i, index) => (
          <Row className={cn('content')} key={index}>
            <Column initial={6}>
              <div className={cn('textContainer')}>
                <CircularHeading4 className={cn('title')}>{i.region_title.text}</CircularHeading4>
                <div className={cn('text')}>
                  <RichText render={i.text?.raw} htmlSerializer={htmlSerializer()} />
                </div>
              </div>
            </Column>
            <Column initial={6}>
              {showGlobe && (
                <GlobeCanvas
                  countries={i.selected_countries}
                  lng={i.globe_rotation.longitude}
                  lat={i.globe_rotation.latitude}
                  className={s.globeCanvasMobile}
                  // @ts-ignore empty string is valid to reset to stylesheet value
                  style={{ position: '' }}
                />
              )}
            </Column>
          </Row>
        ))}
      </Container>
    )
  }

  return (
    <StickyItems items={itemsDesktop} onChange={updateGlobe}>
      <Container>
        <Row>
          <Column initial={6} medium={3} className={cn({ leftAlignText: data.layout === 'Left' })}>
            {showGlobe && (
              <GlobeCanvas
                countries={globeCountries}
                lng={globeCoordinates.longitude}
                lat={globeCoordinates.latitude}
                className={s.globeCanvasDesktop}
              />
            )}
          </Column>
        </Row>
      </Container>
    </StickyItems>
  )
}

export default Data3DGlobe
