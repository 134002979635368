import React from 'react'
import classNames from 'classnames/bind'

import Stickers from 'components/ui/Stickers'
import { Row, Column } from 'components/core/Grid'
import Container from 'components/core/Container'
import { CircularHeading2 } from 'components/ui/Text'

import { ShortExplanationStickersProps } from './ShortExplanationStickersTypes'
import * as s from './ShortExplanationStickers.module.scss'

const cn = classNames.bind(s)

const ShortExplanationStickers = ({ data, items }: ShortExplanationStickersProps) => {
  return (
    <Container className={cn('container')}>
      <Row>
        <Column initial={0} medium={1} />
        <Column initial={6} medium={4} className={cn('content')}>
          {data.text && (
            <CircularHeading2 className={cn('title')} as='p'>
              {data.text.text}
            </CircularHeading2>
          )}
          {items.length > 0 && <Stickers items={items} />}
        </Column>
        <Column initial={0} medium={1} />
      </Row>
    </Container>
  )
}

export default ShortExplanationStickers
