import React, { useMemo } from 'react'
import classNames from 'classnames/bind'

import { MonumentHeading1 } from 'components/ui/Text'
const cn = classNames.bind(s)

import TrailItem from './TrailItem'
import { TrailingTextProps } from './TrailingTextTypes'
import * as s from './TrailingText.module.scss'

const TrailingText = ({ children, motionValue }: TrailingTextProps) => {
  const trail = new Array(9).fill(children)
  const len = useMemo(() => trail.length - 1, [trail])

  return (
    <div className={cn('container')}>
      <MonumentHeading1 className={cn('text')} as='h2'>
        {trail.map((item, index) => (
          <TrailItem key={`item-${index}`} index={index} len={len} motionValue={motionValue}>
            {item}
          </TrailItem>
        ))}
        {/* reference text */}
        <span style={{ opacity: 0, pointerEvents: 'none' }}>{children}</span>
      </MonumentHeading1>
    </div>
  )
}

export default TrailingText
