import React, { useMemo, useCallback, useState, useLayoutEffect } from 'react'
import { MotionValue } from 'framer-motion'
import { RichText } from 'prismic-reactjs'
import classNames from 'classnames/bind'
import { GatsbyImage } from 'gatsby-plugin-image'

import CrossFadeContainer from 'components/core/CrossFadeContainer'
import htmlSerializer from 'prismic/htmlSerializer'
import TrailingText, { TrailingTextStatic } from 'components/ui/TrailingText'
import { MotionValueContext } from 'components/core/StickyItems'
import Spacer from 'components/core/Spacer'
import LottieAnimation from 'components/core/LottieAnimation'
import useUIContext from 'context/ui'

import * as s from './DataVizTimeline.module.scss'
import { DataVizTimelineProps } from './DataVizTimelineTypes'
import UnmaskedChildren from './UnmaskedChildren'
import MaskedMobileImage from './MaskedMobileImage'

const cn = classNames.bind(s)

const DataVizTimeline = ({ data, items, type }: DataVizTimelineProps) => {
  const layout = data.layout.replace('2 Column ', '')
  const [activeIndex, setActiveIndex] = useState(0)
  const [showAnimation, setShowAnimation] = useState(false)
  const isFactPageScrollable = useUIContext(s => s.isFactPageScrollable)

  const unmaskedTimeline = type === 'data_viz_unmasking_timeline'
  const unmaskPercentageList = useMemo(() => {
    if (!unmaskedTimeline) return
    return items.map(i => parseInt(i?.unmask_percentage || '0', 10) / 100)
  }, [items, unmaskedTimeline])

  useLayoutEffect(() => {
    if (isFactPageScrollable) setShowAnimation(true)
  }, [isFactPageScrollable])

  const itemsMobile = useMemo(() => {
    const { background_layer, foreground_layer, layout } = data

    return items.map((i, index) => ({
      ...i,
      layout: layout,
      children: (
        <>
          <TrailingTextStatic>{i.date?.text || ''}</TrailingTextStatic>
          <Spacer initial='10vw' medium='0vw' />
        </>
      ),
      ...(unmaskedTimeline &&
        background_layer &&
        foreground_layer && {
          image: (
            <MaskedMobileImage
              backgroundImage={background_layer}
              foregroundImage={foreground_layer}
              percentage={unmaskPercentageList?.[index] || 0}
            />
          ),
        }),
    }))
  }, [data, items, unmaskedTimeline, unmaskPercentageList])

  const itemsDesktop = useMemo(() => {
    return items.map(item => {
      // alias date doesn't work in preview query
      const itemDate = item.date?.text || item.title?.text
      return {
        layout,

        animation: item.animation?.url ? (
          <div className={cn('relative')}>
            <div className={cn('imageContainer')} {...(item?.image?.alt && { 'aria-label': item.image.alt })}>
              {showAnimation && (
                <span aria-hidden='true'>
                  <LottieAnimation json={item.animation.url} className={cn('image')} />
                </span>
              )}
            </div>
          </div>
        ) : null,

        image: item.image ? (
          <div className={cn('relative')}>
            <div className={cn('imageContainer')}>
              <GatsbyImage
                objectFit='contain'
                className={cn('image')}
                image={item.image.gatsbyImageData}
                alt={item.image.alt || ''}
              />
            </div>
          </div>
        ) : null,

        text:
          item.text?.raw && itemDate ? (
            <div className={cn('textContainer')}>
              <MotionValueContext.Consumer>
                {/* @ts-ignore */}
                {(obj: { motionValue: MotionValue }) => (
                  <TrailingText motionValue={obj.motionValue}>{itemDate || ''}</TrailingText>
                )}
              </MotionValueContext.Consumer>
              <RichText render={item.text.raw} htmlSerializer={htmlSerializer} />
            </div>
          ) : null,
      }
    })
  }, [items, layout, showAnimation])

  /* Used by `unmaskedTimeline` */
  const unmaskTimeline = useCallback((index: number) => setActiveIndex(index), [])

  return (
    <CrossFadeContainer
      numberOfItems={items.length}
      itemsMobile={itemsMobile}
      itemsDesktop={itemsDesktop}
      {...(unmaskedTimeline && {
        desktopChildren: (
          <UnmaskedChildren layout={layout} data={data} progress={unmaskPercentageList?.[activeIndex] || 0} />
        ),
        onChange: unmaskTimeline,
      })}
    />
  )
}

export default DataVizTimeline
