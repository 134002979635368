import React from 'react'
import classNames from 'classnames/bind'
import { GatsbyImage } from 'gatsby-plugin-image'

import { Row, Column } from 'components/core/Grid'
import Container from 'components/core/Container'
import { ParallaxLayer, ParallaxLayers } from 'components/ui/ParallaxLayers'
import getArtDirectedImage from 'lib/getArtDirectedImage'
import renderSpecialCharacters from 'lib/renderSpecialCharacters'
import { CircularHeading3 } from 'components/ui/Text'
import useUIContext from 'context/ui'

import { SparkSentenceTwoColumnTypes } from './SparkSentenceTwoColumnTypes'
import * as s from './SparkSentenceTwoColumn.module.scss'

const cn = classNames.bind(s)
const mediumBreakpoint = parseInt(s.breakpointMedium)

const SparkSentenceTwoColumn = ({ data }: SparkSentenceTwoColumnTypes) => {
  const rightAlignImage = data.layout === 'Left'
  const foregroundImage = data.foreground_layer ? getArtDirectedImage(data.foreground_layer, mediumBreakpoint) : null
  const backgroundImage = data.background_layer ? getArtDirectedImage(data.background_layer, mediumBreakpoint) : null
  const decodedString = renderSpecialCharacters(data?.text?.text || '')
  const ready = useUIContext(s => s.isFactPageScrollable)
  const alt = data?.foreground_layer?.alt

  return (
    <Container className={cn('container', { ready })}>
      <Row className={cn('content', { rightAlignImage })}>
        <Column initial={6} medium={3} className={cn('image')}>
          <ParallaxLayers type='twoColumn'>
            {backgroundImage && (
              <ParallaxLayer isAbsolute={!!foregroundImage} intensity={ready ? 0.33 : 0}>
                <div className={cn('imageLayer', 'bg')}>
                  <GatsbyImage
                    className={cn('image')}
                    objectFit={'contain'}
                    image={backgroundImage}
                    alt={''}
                    aria-hidden='true'
                  />
                </div>
              </ParallaxLayer>
            )}
            {foregroundImage && (
              <ParallaxLayer intensity={ready ? 1.33 : 0}>
                <div className={cn('imageLayer', 'fg')}>
                  <GatsbyImage
                    className={cn('image')}
                    objectFit={'contain'}
                    image={foregroundImage}
                    alt={alt || ''}
                    {...(!alt && { role: 'presentation' })}
                  />
                </div>
              </ParallaxLayer>
            )}
          </ParallaxLayers>
        </Column>
        <Column initial={6} medium={3} className={cn('text')}>
          <div className={cn('textContent')}>
            {data.text && (
              <div style={{ position: 'relative' }}>
                <CircularHeading3 as='h1'>{decodedString}</CircularHeading3>
              </div>
            )}
          </div>
        </Column>
      </Row>
    </Container>
  )
}

export default SparkSentenceTwoColumn
