/**
 * This functions takes a string with spaces and returns another string,
 * with consistent casings etc.
 *
 * @param str A string in the format `Bottom Left`
 * @returns a string that can be used as a style class, formatted as `bottomLeft`
 */
const getPositionClass = (str: string) => {
  return str.split(' ').reduce((acc, item, index) => {
    if (index === 0) acc += item.toLowerCase()
    else acc += item
    return acc
  }, '')
}

export default getPositionClass
