// extracted by mini-css-extract-plugin
export var colorBlack = "#000";
export var colorGray = "gray";
export var colorDarkGray = "#262626";
export var colorLightGray = "#e5e5e5";
export var colorWhite = "#fff";
export var colorElectricBlue = "#0000fe";
export var colorPastelBlue = "#bbe5fe";
export var colorCandyPink = "#f9709a";
export var colorPastelPink = "#ffcdd2";
export var colorDefaultLime = "#cef564";
export var colorPastelLime = "#eeffbe";
export var colorBlueLagoon = "#9cf0e1";
export var colorPastelLagoon = "#ceffec";
export var colorDefaultLavender = "#8738f6";
export var colorPastelLavender = "#eadcff";
export var colorBrightYellow = "#ffe500";
export var colorDefaultGreen = "#12b968";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1024px";
export var breakpointLarge = "1440px";
export var colCount = "6";
export var colWidth = "16.6666666667%";
export var container = "Data3DGlobe-module--container--2X6wk";
export var content = "Data3DGlobe-module--content--3NFLO";
export var globeCanvasMobile = "Data3DGlobe-module--globeCanvasMobile--1C-lf";
export var globeCanvasDesktop = "Data3DGlobe-module--globeCanvasDesktop--LW77z";
export var title = "Data3DGlobe-module--title--2FfqZ";
export var text = "Data3DGlobe-module--text--3pcES";
export var leftAlignText = "Data3DGlobe-module--leftAlignText--1lZ5T";
export var textContainer = "Data3DGlobe-module--textContainer--2phI1";