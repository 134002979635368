import React from 'react'
import classNames from 'classnames/bind'

import Reactions from 'components/ui/Reactions'

import * as s from './StickyReactions.module.scss'

const cn = classNames.bind(s)

const StickyReactions = () => {
  return (
    <div className={cn('sticky')}>
      <div className={cn('reactions')}>
        <Reactions />
      </div>
    </div>
  )
}

export default StickyReactions
