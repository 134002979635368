import { withArtDirection, IGatsbyImageData } from 'gatsby-plugin-image'
export type ImageTypes = {
  alt: string
  gatsbyImageData: IGatsbyImageData
  thumbnails?: {
    mobile: {
      gatsbyImageData: IGatsbyImageData
    }
  }
}

const getArtDirectedImage = (image: ImageTypes, breakpoint: number) => {
  if (!image.gatsbyImageData) return null
  return withArtDirection(image.gatsbyImageData, [
    {
      media: `(max-width: ${breakpoint}px)`,
      image: image.thumbnails ? image.thumbnails.mobile.gatsbyImageData : image.gatsbyImageData,
    },
  ])
}

export default getArtDirectedImage
