// extracted by mini-css-extract-plugin
export var colorBlack = "#000";
export var colorGray = "gray";
export var colorDarkGray = "#262626";
export var colorLightGray = "#e5e5e5";
export var colorWhite = "#fff";
export var colorElectricBlue = "#0000fe";
export var colorPastelBlue = "#bbe5fe";
export var colorCandyPink = "#f9709a";
export var colorPastelPink = "#ffcdd2";
export var colorDefaultLime = "#cef564";
export var colorPastelLime = "#eeffbe";
export var colorBlueLagoon = "#9cf0e1";
export var colorPastelLagoon = "#ceffec";
export var colorDefaultLavender = "#8738f6";
export var colorPastelLavender = "#eadcff";
export var colorBrightYellow = "#ffe500";
export var colorDefaultGreen = "#12b968";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1024px";
export var breakpointLarge = "1440px";
export var colCount = "6";
export var colWidth = "16.6666666667%";
export var container = "StickyItems-module--container--3Y2wf";
export var stickyContainer = "StickyItems-module--stickyContainer--25jil";
export var image = "StickyItems-module--image--xe7j3";
export var animate = "StickyItems-module--animate--1ikbC";
export var section = "StickyItems-module--section--j7x38";
export var text = "StickyItems-module--text--2leAh";
export var isTransitioning = "StickyItems-module--isTransitioning--1Og0G";
export var inner = "StickyItems-module--inner--a4oks";