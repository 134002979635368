import React from 'react'
import classNames from 'classnames/bind'
import { GatsbyImage } from 'gatsby-plugin-image'

import * as s from './DataVizTimeline.module.scss'
import { MaskedImage } from './DataVizTimelineTypes'

const cn = classNames.bind(s)

const MaskedMobileImage = ({ backgroundImage, foregroundImage, percentage }: MaskedImage) => (
  <div className={cn('maskedContainerMobile')}>
    <GatsbyImage
      className={cn('background')}
      image={backgroundImage.gatsbyImageData}
      role='presentation'
      alt=''
      objectFit='contain'
    />
    <GatsbyImage
      style={
        {
          clipPath: `inset(0 ${(1 - percentage) * 100}% 0 0)`,
        } as React.CSSProperties
      }
      className={cn('foreground')}
      image={foregroundImage.gatsbyImageData}
      alt={foregroundImage.alt || ''}
      objectFit='contain'
    />
  </div>
)

export default MaskedMobileImage
