import React from 'react'
import { Elements } from 'prismic-richtext'

import htmlSerializer from 'prismic/htmlSerializer'
import { CircularBodyLarge } from 'components/ui/Text'

const serializer = desktop => {
  return (type, _element, content, children, key) => {
    if (desktop) return htmlSerializer(type, _element, content, children, key)

    /* On mobile, headings and paragraphs should all be treated as p. */
    switch (type) {
      case Elements.paragraph:
      case Elements.heading3:
      case Elements.heading4:
        return <CircularBodyLarge key={key}>{children}</CircularBodyLarge>

      default:
        return htmlSerializer(type, _element, content, children, key)
    }
  }
}

export default serializer
