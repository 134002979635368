/* eslint-disable react/display-name */
import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
import classNames from 'classnames/bind'
import lottie, { AnimationItem } from 'lottie-web'
import isEqual from 'lodash.isequal'

import ViewportEnter from 'components/core/ViewportEnter'
import useIsDesktop from 'lib/useIsDesktop'

import { LottieAnimationType } from './LottieAnimationTypes'
import * as s from './LottieAnimation.module.scss'

const cn = classNames.bind(s)

const LottieAnimation = React.forwardRef<AnimationItem, LottieAnimationType>(
  (
    {
      json,
      jsonMobile,
      useMobileJson,
      loop = false,
      autoplay = true,
      once = true,
      viewportEnterCallback,
      className,
      onEnterFrame,
      isInView: _isInView,
      name = 'LottieAnimation',
    },
    animationRef,
  ) => {
    const [isInView, setIsInView] = useState<boolean>(_isInView || false)
    const [animationData, setAnimationData] = useState<string | Record<string, unknown> | undefined>({})
    const isDesktop = useIsDesktop()

    const ref = useRef<HTMLDivElement>(null)
    const _animationRef = useRef<AnimationItem>()
    const animation = (animationRef || _animationRef) as MutableRefObject<AnimationItem | null>

    const hasPlayed = useRef<boolean>(false)

    useEffect(() => {
      const jsonData = typeof animationData === 'string' ? { path: animationData } : { animationData: animationData }
      animation.current = lottie.loadAnimation({
        name,
        container: ref.current as Element,
        renderer: 'svg',
        loop,
        autoplay: false,
        ...jsonData,
      }) as AnimationItem

      lottie.setQuality('high')

      return () => {
        animation.current?.destroy()
        animation.current = null
      }
    }, [name, animationData, loop, animation])

    useEffect(() => {
      hasPlayed.current = false

      if (isInView && autoplay) {
        animation?.current?.play()

        hasPlayed.current = true
      }

      if (isInView && onEnterFrame) {
        animation?.current?.addEventListener('enterFrame', onEnterFrame)
      }

      return () => {
        if (onEnterFrame) {
          animation?.current?.removeEventListener('enterFrame', onEnterFrame)
          animation?.current?.goToAndStop(0)
        }
      }
    }, [animation, autoplay, isInView, loop, onEnterFrame, name])

    useEffect(() => {
      if (isInView) {
        if (viewportEnterCallback) viewportEnterCallback()

        if (autoplay && (!hasPlayed.current || !once)) {
          animation?.current?.goToAndStop(0)
          animation?.current?.play()
        }
      }
    }, [animation, isInView, viewportEnterCallback, autoplay, once])

    useEffect(() => {
      setIsInView(_isInView || false)
    }, [_isInView])

    useEffect(() => {
      if (!isEqual(animationData, useMobileJson ? jsonMobile : json)) {
        setAnimationData(useMobileJson ? jsonMobile : json)
      }
    }, [animation, animationData, json, jsonMobile, name, useMobileJson])

    return (
      <ViewportEnter
        onEnter={() => setIsInView(true)}
        onExit={() => setIsInView(false)}
        once={once}
        threshold={isDesktop ? 0.4 : 0.15}
      >
        <div ref={ref} role='presentation' className={cn('animation', className)} />
      </ViewportEnter>
    )
  },
)

export default LottieAnimation
