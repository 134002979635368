import React, { useState, useCallback, useMemo, useLayoutEffect } from 'react'
import classNames from 'classnames/bind'
import { motion, useAnimation, AnimatePresence } from 'framer-motion'

import useUIContext from 'context/ui'
import useCopyContext from 'context/copy'
import useIsDesktop from 'lib/useIsDesktop'
import Plus from 'assets/svg/plus.react.svg'
import { CircularCaption } from 'components/ui/Text'
import { TooltipBox } from 'components/ui/Tooltip'
import LottieAnimation from 'components/core/LottieAnimation'

import { variants, floatingAnimation, scaleUpAnimation, fallDownAnimation, bounceOnHoverAnimation } from './variants'
import { ReactionProps } from './ReactionsTypes'
import * as s from './Reactions.module.scss'
const cn = classNames.bind(s)

const Reaction = ({
  item,
  label,
  onClick,
  interacting /* While interacting, any other stored reaction should not be marked yellow */,
  totalReactions,
  storedReaction,
  minCountContainerWidth,
}: ReactionProps) => {
  const isDesktop = useIsDesktop()
  const iconControls = useAnimation()
  const floatingControls = useAnimation()
  const isFactPageScrollable = useUIContext(s => s.isFactPageScrollable)
  const copy = useCopyContext(s => s.copy)

  const [showAnimation, setShowAnimation] = useState(false)
  const [animating, setAnimating] = useState<boolean>(false) /* Not only Lottie animation but also bounce/scale etc. */
  const [showTooltip, setShowTooltip] = useState<boolean>(false)
  const [playLottie, setPlayLottie] = useState<boolean>(false)
  const [hasPlayed, setHasPlayed] =
    useState<boolean>(false) /* Needed in mobile to not trigger autoplay when scrolling into view */
  const [mouseOver, setMouseOver] =
    useState<boolean>(false) /* Firefox fix to avoid triggering onMouseEnter multiple times */
  const showLottie = useMemo(() => {
    return isDesktop ? playLottie : playLottie || (storedReaction && hasPlayed)
  }, [isDesktop, playLottie, storedReaction, hasPlayed])

  useLayoutEffect(() => {
    if (isFactPageScrollable) setShowAnimation(true)
  }, [isFactPageScrollable])

  const handleClick = useCallback(() => {
    interacting(true)
    setAnimating(true)
    setShowTooltip(false)

    floatingControls.start(floatingAnimation)

    /* Scale up on click and then fall down */
    iconControls.start(scaleUpAnimation).then(() => {
      iconControls.start(fallDownAnimation)
      setAnimating(false)
      setPlayLottie(false)
      interacting(false)
      onClick()
    })
  }, [onClick, interacting, iconControls, floatingControls])

  /* Handle mobile click separately since mouseEnter/mouseLeave is not triggered */
  const handleClickMobile = useCallback(() => {
    interacting(true)
    setPlayLottie(true)
    if (!hasPlayed) setHasPlayed(true)
    onClick()

    iconControls.start(bounceOnHoverAnimation).then(() => {
      floatingControls.start(floatingAnimation)
      iconControls.start(fallDownAnimation).then(() => {
        setPlayLottie(false)
        interacting(false)
      })
    })
  }, [iconControls, floatingControls, interacting, onClick, hasPlayed])

  return (
    <motion.button
      aria-label={`${totalReactions || 0} ${copy.reaction_aria_label} ${label}`}
      className={cn('button')}
      onClick={isDesktop ? handleClick : handleClickMobile}
      {...(isDesktop && {
        onMouseEnter: () => {
          if (animating || mouseOver) return
          setMouseOver(true)
          setShowTooltip(true)
          setPlayLottie(true)
          interacting(true)
          iconControls.start(bounceOnHoverAnimation)
        },
        onMouseLeave: () => {
          if (animating) return
          setShowTooltip(false)
          setPlayLottie(false)
          interacting(false)
          setMouseOver(false)
          iconControls.start({ y: '0%', scale: 1 })
        },
      })}
    >
      <div className={cn('relative')} aria-hidden='true'>
        <AnimatePresence>
          {showTooltip && label && (
            <TooltipBox className={cn('tooltip')} variants={variants}>
              {label}
            </TooltipBox>
          )}
        </AnimatePresence>
        <motion.span className={cn('iconContainer', { storedReaction })} animate={iconControls}>
          {showLottie && showAnimation ? (
            <LottieAnimation className={cn('lottie')} json={item.json} autoplay={showLottie || false} />
          ) : (
            item.icon
          )}
        </motion.span>
        <div className={cn('floating')}>
          <motion.span className={cn('plus')} animate={floatingControls} custom={{ direction: 1, index: 0 }}>
            <Plus />
          </motion.span>
        </div>
      </div>
      <div
        className={cn('countContainer')}
        style={{ '--container-min-width': minCountContainerWidth } as React.CSSProperties}
      >
        <AnimatePresence exitBeforeEnter>
          {totalReactions && totalReactions !== '0' && (
            <motion.span
              initial={{ y: '20%', opacity: 0 }}
              animate={{ y: '0%', opacity: 1 }}
              exit={{ y: '-20%', opacity: 0 }}
              transition={{ type: 'spring', duration: 0.25 }}
              key={totalReactions}
            >
              <CircularCaption aria-live='polite' role='status' as='span' className={cn('count')} weight='book'>
                {totalReactions}
              </CircularCaption>
            </motion.span>
          )}
        </AnimatePresence>
      </div>
    </motion.button>
  )
}

export default Reaction
