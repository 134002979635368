import React from 'react'
import classNames from 'classnames/bind'

import { MonumentHeading2 } from 'components/ui/Text'

import * as s from './FloatingBanner.module.scss'
import { FloatingBannerProps, MarqueeProps } from './FloatingBannerTypes'

const cn = classNames.bind(s)

const FloatingBanner = ({ data }: FloatingBannerProps) => {
  const duplicateBanners = data.number_of_banners === 'Two'
  const text = data?.text ? new Array(4).fill(data.text.text) : []

  return (
    <div className={cn('container')}>
      <div className={cn('inner')}>
        <Marquee text={text} />
        {duplicateBanners && <Marquee text={text} inverted />}
      </div>
    </div>
  )
}

const Marquee = ({ text, inverted }: MarqueeProps) => {
  return (
    <div className={cn('marquee', { inverted })}>
      <div className={cn('content')}>
        {text.map((t, index) => (
          <MonumentHeading2
            weight='regular'
            className={cn('text')}
            key={`text-${index}`}
            {...(index !== 0 && { 'aria-hidden': 'true' })}
          >
            {t}

            <span className={cn('dot')} aria-hidden='true'>
              •
            </span>
          </MonumentHeading2>
        ))}
      </div>
    </div>
  )
}

export default FloatingBanner
