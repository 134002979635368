import React from 'react'
import classNames from 'classnames/bind'
import { Elements } from 'prismic-richtext'

import { CircularBody } from 'components/ui/Text'

import * as s from './ExampleImageBackground.module.scss'
const cn = classNames.bind(s)

const serializer = (type, _element, _content, children, key) => {
  switch (type) {
    case Elements.heading3:
      return (
        <CircularBody className={cn('text')} as='h3' key={key}>
          {children}
        </CircularBody>
      )

    case Elements.paragraph:
      return (
        <CircularBody className={cn('text')} weight='book' key={key}>
          {children}
        </CircularBody>
      )

    default:
      return null
  }
}

export default serializer
