import React, { useRef, useState, Fragment } from 'react'
import classNames from 'classnames/bind'
import { RichText } from 'prismic-reactjs'

import { CircularHeading4, CircularBody } from 'components/ui/Text'
import useIsDesktop from 'lib/useIsDesktop'
import useCopyContext from 'context/copy'
import Clickable from 'components/core/Clickable'
import Container from 'components/core/Container'
import Popup from 'components/ui/Popup'
import ViewportEnterEffect from 'components/core/ViewportEnterEffect'
import Blob1 from 'assets/svg/blob1.react.svg'
import Blob2 from 'assets/svg/blob2.react.svg'
import Blob3 from 'assets/svg/blob3.react.svg'
import Blob4 from 'assets/svg/blob4.react.svg'
import Blob5 from 'assets/svg/blob5.react.svg'
import Blob6 from 'assets/svg/blob6.react.svg'
import WorldMapSvg from 'assets/svg/world-map.react.svg'
import TooltipSvg from 'assets/svg/tooltip-black.react.svg'

import serializer from './serializer'
import { WorldMapProps, HotspotProps } from './WorldMapTypes'
import * as s from './WorldMap.module.scss'

const cn = classNames.bind(s)

const blobs = [Blob1, Blob2, Blob3, Blob4, Blob5, Blob6]

const EFFECT_DELAY_MS = 200

const WorldMap = ({ data: { section_title, popup_text, map_color, blobs_color }, items }: WorldMapProps) => {
  const [activePopup, setActivePopup] = useState<number | null>(null)
  const [isTitlePopupOpen, setIsTitlePopupOpen] = useState<boolean>(false)
  const isDesktop = useIsDesktop()
  const mapWrapperRef = useRef<HTMLDivElement>(null)
  const { hotspot_size_label } = useCopyContext(s => s.copy)

  const closePopup = () => {
    setActivePopup(null)
  }

  const toRadian = (value: number) => {
    return (value * Math.PI) / 180
  }

  const millerProjection = (latitude: number, longitude: number) => {
    longitude = toRadian(longitude)
    latitude = toRadian(latitude)

    let x = longitude
    let y = -1.25 * Math.log(Math.tan(Math.PI / 4 + 0.4 * latitude))

    const width = mapWrapperRef.current?.clientWidth || 0
    const scale = width / Math.PI / 2

    x *= scale
    y *= scale

    x += width / 2
    y += (width / 2) * 0.7331989845

    return { x, y }
  }

  return (
    <Container className={cn('container')}>
      <CircularHeading4 className={cn('title')} as='p'>
        <RichText
          render={section_title?.raw}
          htmlSerializer={serializer(popup_text, isTitlePopupOpen, (open: boolean) => setIsTitlePopupOpen(open))}
        />
      </CircularHeading4>

      <div className={cn('mapWrapper')} ref={mapWrapperRef} style={{ color: map_color }}>
        <WorldMapSvg className={cn('worldMapSvg')} />

        {items.map(({ hotspot_location, hotspot_title, hotspot_text, hotspot_size }: HotspotProps, index) => {
          const coordinates = millerProjection(hotspot_location.latitude, hotspot_location.longitude)
          const isActive = index === activePopup
          const Blob = blobs[index % blobs.length || 0]

          return (
            <Fragment key={index}>
              <div
                className={cn('hotspotWrapper')}
                onMouseEnter={() => setActivePopup(index)}
                onMouseLeave={closePopup}
                style={{
                  transform: `translate(calc(${coordinates.x}px - 50%), calc(${coordinates.y}px - 50%))`,
                  zIndex: isActive ? 2 : 1,
                }}
              >
                <Clickable
                  className={cn('hotspot')}
                  onClick={() => setActivePopup(index)}
                  aria-haspopup='dialog'
                  {...(hotspot_title && { 'aria-label': hotspot_title })}
                >
                  <TooltipSvg className={cn('hotspotIcon')} aria-hidden='true' />
                </Clickable>

                {isDesktop && (
                  <Popup
                    open={isActive}
                    onClose={closePopup}
                    className={cn('popup')}
                    top='0'
                    translateY='calc(-100% - 1.5vw)'
                    ariaLabel={`${hotspot_title}: ${hotspot_text}`}
                  >
                    <CircularBody className={cn('popupTitle')}>{hotspot_title}</CircularBody>
                    <CircularBody>{hotspot_text}</CircularBody>
                  </Popup>
                )}
              </div>
              <div
                className={cn('blob')}
                style={{
                  width: `${hotspot_size * (isDesktop ? 1 : 1.7)}%`,
                  height: `${hotspot_size * (isDesktop ? 1 : 1.7)}%`,
                  transform: `translate(calc(${coordinates.x}px - 50%), calc(${coordinates.y}px - 50%))`,
                }}
              >
                <ViewportEnterEffect effect='scaleUp' delayMs={EFFECT_DELAY_MS * index}>
                  <Blob
                    className={cn('blobIcon')}
                    style={{ color: blobs_color }}
                    aria-label={`${hotspot_size_label} ${hotspot_size} %`}
                  />
                </ViewportEnterEffect>
              </div>

              {!isDesktop && (
                <Popup open={isActive} onClose={closePopup} ariaLabel={`${hotspot_title}: ${hotspot_text}`}>
                  <CircularBody className={cn('popupTitle')}>{hotspot_title}</CircularBody>
                  <CircularBody>{hotspot_text}</CircularBody>
                </Popup>
              )}
            </Fragment>
          )
        })}
      </div>
    </Container>
  )
}

export default WorldMap
