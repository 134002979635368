import React, { useMemo } from 'react'
import classNames from 'classnames/bind'
import { motion } from 'framer-motion'

import { MonumentHeading1 } from 'components/ui/Text'

const cn = classNames.bind(s)
import * as s from './TrailingText.module.scss'
import { getStopY } from './TrailItem'

/**
 * This is a static version of the `TrailingText` component used on smaller screens
 */
const TrailingTextStatic = ({ children }: { children: string }) => {
  const trail = new Array(9).fill(children)
  const len = useMemo(() => trail.length - 1, [trail])

  return (
    <div className={cn('container')}>
      <MonumentHeading1 className={cn('text')} as='h3'>
        {trail.map((item, index) => {
          const first = index === 0
          return (
            <motion.span
              key={`item-${index}`}
              className={cn('parallax')}
              style={{ zIndex: index * -1, y: getStopY(len, index) }}
            >
              <span className={cn({ first, sibling: !first })} role='presentation'>
                {item}
              </span>
            </motion.span>
          )
        })}
        {/* reference text */}
        <span style={{ opacity: 0, pointerEvents: 'none' }}>{children}</span>
      </MonumentHeading1>
    </div>
  )
}

export default TrailingTextStatic
