// extracted by mini-css-extract-plugin
export var colorBlack = "#000";
export var colorGray = "gray";
export var colorDarkGray = "#262626";
export var colorLightGray = "#e5e5e5";
export var colorWhite = "#fff";
export var colorElectricBlue = "#0000fe";
export var colorPastelBlue = "#bbe5fe";
export var colorCandyPink = "#f9709a";
export var colorPastelPink = "#ffcdd2";
export var colorDefaultLime = "#cef564";
export var colorPastelLime = "#eeffbe";
export var colorBlueLagoon = "#9cf0e1";
export var colorPastelLagoon = "#ceffec";
export var colorDefaultLavender = "#8738f6";
export var colorPastelLavender = "#eadcff";
export var colorBrightYellow = "#ffe500";
export var colorDefaultGreen = "#12b968";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1024px";
export var breakpointLarge = "1440px";
export var colCount = "6";
export var colWidth = "16.6666666667%";
export var effectWrapper = "ViewportEnterEffect-module--effectWrapper--xLhJm";
export var fit = "ViewportEnterEffect-module--fit--1oGLf";
export var effect = "ViewportEnterEffect-module--effect--1EdLL";
export var mask = "ViewportEnterEffect-module--mask--_mzoh";
export var fillHeight = "ViewportEnterEffect-module--fillHeight--uPv7I";
export var text = "ViewportEnterEffect-module--text--3hqzr";
export var visible = "ViewportEnterEffect-module--visible--tbQUI";
export var fade = "ViewportEnterEffect-module--fade--3BfeH";
export var slideUp = "ViewportEnterEffect-module--slideUp--3Qk_j";
export var slideRight = "ViewportEnterEffect-module--slideRight--2yMFs";
export var scaleDown = "ViewportEnterEffect-module--scaleDown--UY-aR";
export var scaleUp = "ViewportEnterEffect-module--scaleUp--Jdr_r";
export var drawLineHorizontal = "ViewportEnterEffect-module--drawLineHorizontal--3Xs7a";
export var drawLineVertical = "ViewportEnterEffect-module--drawLineVertical--3sp6F";