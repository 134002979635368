import React from 'react'

import TwoColumn from 'components/ui/TwoColumn'
import DataVizGeneric from 'components/ui/DataVizGeneric'
import DataVizTimeline from 'components/ui/DataVizTimeline'
import SparkSentenceTwoColumn from 'components/ui/SparkSentenceTwoColumn'
import SparkSentenceFullScreen from 'components/ui/SparkSentenceFullScreen'
import ShortExplanationIcons from 'components/ui/ShortExplanationIcons'
import ShortExplanationPills from 'components/ui/ShortExplanationPills'
import ShortExplanationStickers from 'components/ui/ShortExplanationStickers'
import ShortExplanationOutline from 'components/ui/ShortExplanationOutline'
import FloatingBanner from 'components/ui/FloatingBanner'
import Takeaway from 'components/ui/Takeaway'
import ExampleDragAndReveal from 'components/ui/ExampleDragAndReveal'
import ExampleImageBackground from 'components/ui/ExampleImageBackground'
import DataPieChart from 'components/ui/DataPieChart'
import Data3DGlobe from 'components/ui/Data3DGlobe'
import WorldMap from 'components/ui/WorldMap'
import TextSection from 'components/ui/TextSection'
import Steps from 'components/ui/Steps'

import { SliceRendererProps } from './SliceRendererTypes'

const SliceRenderer = ({ slices }: SliceRendererProps) => (
  <>
    {slices.map((slice, index) => {
      if (!slice) return null
      const res = (() => {
        switch (slice.slice_type) {
          case 'generic':
            return <TwoColumn data={slice.primary} />

          case 'spark_sentence_-_two_column':
            return <SparkSentenceTwoColumn data={slice.primary} />

          case 'spark_sentence_-_fullscreen':
            return <SparkSentenceFullScreen data={slice.primary} />

          case 'short_explanation_icons':
            return <ShortExplanationIcons data={slice.primary} />

          case 'short_explanation_pills':
            return <ShortExplanationPills data={slice.primary} />

          case 'short_explanation_outline':
            return <ShortExplanationOutline data={slice.primary} items={slice.items} />

          case 'short_explanation_stickers':
            return <ShortExplanationStickers data={slice.primary} items={slice.items} />

          case 'floating_banner':
            return <FloatingBanner data={slice.primary} />

          case 'takeaway':
            return <Takeaway data={slice.primary} items={slice.items} />

          case 'example_-_drag_and_reveal':
            return <ExampleDragAndReveal data={slice.primary} />

          case 'example_image_background':
            return <ExampleImageBackground data={slice.primary} items={slice.items} />

          case 'data_pie_chart':
            return <DataPieChart data={slice.primary} items={slice.items} />

          case 'data_viz_crossfade_generic':
            return <DataVizGeneric data={slice.primary} items={slice.items} />

          case 'data_-_3d_globe':
            return <Data3DGlobe data={slice.primary} items={slice.items} />

          case 'data_viz_crossfade_timeline':
            return <DataVizTimeline data={slice.primary} items={slice.items} type={slice.slice_type} />

          case 'data_viz_unmasking_timeline':
            return <DataVizTimeline data={slice.primary} items={slice.items} type={slice.slice_type} />

          case 'world_map':
            return <WorldMap data={slice.primary} items={slice.items} />

          case 'text_section':
            return <TextSection data={slice.primary} />

          case 'steps':
            return <Steps data={slice.primary} items={slice.items} />

          default:
            if (slice.slice_type) console.warn(`There is not yet support for slice of type ${slice.slice_type}`)
            return null
        }
      })()

      return (
        <div id={`section-${index + 1}`} key={index}>
          {res}
        </div>
      )
    })}
  </>
)

export default SliceRenderer
