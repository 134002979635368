// extracted by mini-css-extract-plugin
export var colorBlack = "#000";
export var colorGray = "gray";
export var colorDarkGray = "#262626";
export var colorLightGray = "#e5e5e5";
export var colorWhite = "#fff";
export var colorElectricBlue = "#0000fe";
export var colorPastelBlue = "#bbe5fe";
export var colorCandyPink = "#f9709a";
export var colorPastelPink = "#ffcdd2";
export var colorDefaultLime = "#cef564";
export var colorPastelLime = "#eeffbe";
export var colorBlueLagoon = "#9cf0e1";
export var colorPastelLagoon = "#ceffec";
export var colorDefaultLavender = "#8738f6";
export var colorPastelLavender = "#eadcff";
export var colorBrightYellow = "#ffe500";
export var colorDefaultGreen = "#12b968";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1024px";
export var breakpointLarge = "1440px";
export var colCount = "6";
export var colWidth = "16.6666666667%";
export var dragAndReveal = "ExampleDragAndReveal-module--dragAndReveal--xeQ6_";
export var inner = "ExampleDragAndReveal-module--inner--1IMvN";
export var contentWrapper = "ExampleDragAndReveal-module--contentWrapper--111px";
export var content = "ExampleDragAndReveal-module--content--QMMW9";
export var title = "ExampleDragAndReveal-module--title--bSIQ8";
export var constraintsRef = "ExampleDragAndReveal-module--constraintsRef--2hB5B";
export var revealerWrapper = "ExampleDragAndReveal-module--revealerWrapper--1otSO";
export var image = "ExampleDragAndReveal-module--image--1QQZd";
export var isInView = "ExampleDragAndReveal-module--isInView--3RBOF";
export var image1 = "ExampleDragAndReveal-module--image1--l6lBr";
export var image2 = "ExampleDragAndReveal-module--image2--wO9gw";
export var revealer = "ExampleDragAndReveal-module--revealer--3g2sS";