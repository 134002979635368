import React, { useEffect, useState, useCallback, useRef } from 'react'
import classNames from 'classnames/bind'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useWindowSize } from '@react-hook/window-size'
import { motion, useMotionValue, useSpring } from 'framer-motion'

import ViewportEnter from 'components/core/ViewportEnter'
import ConditionalWrapper from 'lib/conditionalWrapper'
import Interactable from 'components/core/Interactable'

import { StickerProps } from './StickersTypes'
import * as s from './Stickers.module.scss'

const cn = classNames.bind(s)

const _ = {
  x: 0.3,
  y: 0.3,
  amp: 0.3,
  spring: {
    damping: 12,
    stiffness: 230,
  },
}

const Sticker = ({ item, hasPopup, onEnter, onLeave, onClick, len }: StickerProps) => {
  const [isInView, setIsInView] = useState(false)
  const [width, height] = useWindowSize()

  const sticker = useRef<HTMLDivElement>(null)
  const stickerRect = useRef<DOMRect>()

  const x = useMotionValue(0)
  const xSpring = useSpring(0, _.spring)
  const y = useMotionValue(0)
  const ySpring = useSpring(0, _.spring)

  const onMouseMove = useCallback(
    (event: MouseEvent) => {
      x.set(event.clientX)
      y.set(event.clientY)
    },
    [x, y],
  )

  const onTouchMove = useCallback(
    (event: TouchEvent) => {
      x.set(event.changedTouches[0].clientX)
      y.set(event.changedTouches[0].clientY)
    },
    [x, y],
  )

  const calcRect = useCallback(() => {
    if (!sticker.current) return
    stickerRect.current = sticker.current.getBoundingClientRect()
  }, [])

  useEffect(() => {
    calcRect()
  }, [width, height, calcRect])

  useEffect(() => {
    const onXChange = x.onChange(v => {
      if (!stickerRect.current) return
      const r = stickerRect.current

      const offsetX = r.width * _.x
      const isX = v > r.x - offsetX && v < r.x + r.width + offsetX

      const offsetY = r.height * _.y
      const isY = y.get() > r.y - offsetY && y.get() < r.y + r.height + offsetY

      if (isX && isY) {
        const ampX = r.width * _.amp
        const valX = ampX * ((v - r.x) / r.width - 0.5)
        xSpring.set(valX)

        const ampY = r.height * _.amp
        const valY = ampY * ((y.get() - r.y) / r.height - 0.5)
        ySpring.set(valY)
      } else {
        xSpring.get() !== 0 && xSpring.set(0)
        ySpring.get() !== 0 && ySpring.set(0)
      }
    })

    return () => {
      onXChange()
    }
  }, [x, y, xSpring, ySpring])

  useEffect(() => {
    if (isInView) {
      window.addEventListener('mousemove', onMouseMove)
      window.addEventListener('touchstart', onTouchMove)
    } else {
      window.removeEventListener('mousemove', onMouseMove)
      window.removeEventListener('touchstart', onTouchMove)
    }
    return () => {
      window.removeEventListener('mousemove', onMouseMove)
      window.removeEventListener('touchstart', onTouchMove)
    }
  }, [onMouseMove, onTouchMove, isInView])

  return (
    <ViewportEnter onEnter={() => setIsInView(true)} onExit={() => setIsInView(false)} once={false} threshold={0.5}>
      <div className={cn('sticker', { duo: len === 2 })} ref={sticker}>
        <motion.div
          style={{ x: xSpring, y: ySpring }}
          whileHover={{ scale: [1, 1.2, 1.1], transition: { duration: 0.6, times: [0, 0.4, 1], ease: 'easeInOut' } }}
        >
          <div className={cn('inner', { isInView })}>
            <div
              className={cn('rect')}
              style={{ '--offsetX': _.x, '--offsetY': _.y } as React.CSSProperties}
              onMouseEnter={calcRect}
            />
            <ConditionalWrapper
              condition={hasPopup}
              wrapper={children => (
                <Interactable
                  className={cn('interactive')}
                  onClick={onClick}
                  onEnter={onEnter}
                  onLeave={onLeave}
                  aria-haspopup='dialog'
                >
                  {children}
                </Interactable>
              )}
            >
              <>
                <GatsbyImage
                  image={item.image.gatsbyImageData}
                  objectFit='contain'
                  className={cn('image')}
                  alt={item.image?.alt || ''}
                />
              </>
            </ConditionalWrapper>
          </div>
        </motion.div>
      </div>
    </ViewportEnter>
  )
}

export default Sticker
