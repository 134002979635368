import React from 'react'
import classNames from 'classnames/bind'
import { StaticImage } from 'gatsby-plugin-image'

import useSourcesContext from 'context/sources'
import { CircularBody, CircularCaption } from 'components/ui/Text'
import Popup from 'components/ui/Popup'
import Clickable from 'components/core/Clickable'

import { TakeawayPopupProps } from './TakeawayTypes'
import * as s from './Takeaway.module.scss'

const cn = classNames.bind(s)

/* Simple mapping until more complexity is needed */
const UNDP_AUTHOR = 'UNDP'

const TakeawayPopup = ({ sources, copy, onClose, open }: TakeawayPopupProps) => {
  const lastPublicationDate = useSourcesContext(s => s.lastPublicationDate)
  const author = useSourcesContext(s => s.author)

  return (
    <Popup open={open} containerClassName={cn('popup')} onClose={onClose} title={copy.sources_title}>
      <div className={cn('meta')}>
        {author.includes(UNDP_AUTHOR) && (
          <StaticImage className={cn('image')} src={'../../../assets/images/authors/undp.svg'} alt={author} />
        )}
        <div>
          <CircularBody as='h5'>{`${copy.author_prefix} ${author}`}</CircularBody>
          <CircularCaption className={cn('date')} weight='book' as='p'>
            {lastPublicationDate}
          </CircularCaption>
        </div>
      </div>
      <ul>
        {sources.map((s, index) => (
          <li key={`source-${index}`}>
            <Clickable className={cn('source')} to={s.source_url?.url || ''}>
              <CircularCaption as='span' weight='book'>{`${s.source_label} ↗`}</CircularCaption>
            </Clickable>
          </li>
        ))}
      </ul>
    </Popup>
  )
}

export default TakeawayPopup
