import React from 'react'
import classNames from 'classnames/bind'
import { Elements } from 'prismic-richtext'

import InteractiveText from 'components/ui/InteractiveText'
import { MonumentHashtag } from 'components/ui/Text'
import Interactable from 'components/core/Interactable'
import Popup from 'components/ui/Popup'

import * as s from './ShortExplanationOutline.module.scss'
const cn = classNames.bind(s)

const serializer = (popup_items, active, onInteraction) => {
  let count = 0
  const inner = (type, _element, _content, children, key) => {
    switch (type) {
      /*
       * `strong` is used into highlight word.
       */
      case Elements.strong: {
        const currentCount = count
        // Bump count for next round
        count += 1

        const related = popup_items[currentCount]
        const contentType = related?.popup_content?.toLowerCase()

        /* Exit early if editor has forgotten to choose between text/image */
        if (!contentType) return null
        const content = related[`content_${contentType}`]

        return (
          <span className={cn('relative')} key={key}>
            <Interactable
              onClick={() => onInteraction(currentCount)}
              onEnter={() => onInteraction(currentCount)}
              onLeave={() => {
                if (active === currentCount) onInteraction(null)
              }}
            >
              <InteractiveText
                intensity={1.2}
                textColor='var(--theme-forground)'
                textHoverColor='var(--color-white)'
                outlineColor='var(--color-black)'
                primaryOutline={false}
              >
                <MonumentHashtag scale='monumentH2' as='p'>
                  {children}
                </MonumentHashtag>
              </InteractiveText>
            </Interactable>
            <Popup containerClassName={cn('popup')} open={active === currentCount} onClose={() => onInteraction(null)}>
              {content}
            </Popup>
          </span>
        )
      }

      case Elements.heading4:
        return (
          <MonumentHashtag scale='monumentH2' key={key} as='p'>
            {children.map(function eachChild(child, index) {
              if (Array.isArray(child)) return child.map(eachChild)
              if (typeof child === 'string')
                return (
                  <span key={`span-${index}`} className={cn('outline')}>
                    {child}
                  </span>
                )
              return child
            })}
          </MonumentHashtag>
        )

      default:
        return null
    }
  }

  return inner
}

export default serializer
