const numberFormatter = (num: number) => {
  /* Everything above 1 000 000 should be formatted as `+{num}M` */
  if (num >= 1000000) {
    return `+${((Math.floor(num / 1000) * 1000) / 1000000).toFixed(1).replace(/\.0$/, '')}M`
  }

  /* Everything between 1 000 -> 999 999 should be formatted as `+{num}k` */
  if (num >= 1000) {
    const factor = num > 100000 ? 1000 : 100
    return `+${((Math.floor(num / factor) * factor) / 1000).toFixed(1).replace(/\.0$/, '')}k`
  }

  return `${num}`
}

export default numberFormatter
