/*
 *  Currently, the `react-minimal-pie-chart` is not exposing the Typescript types.
 *  Related to this issue: https://github.com/toomuchdesign/react-minimal-pie-chart/issues/230
 */

// @ts-nocheck
import React, { useMemo } from 'react'
import classNames from 'classnames/bind'

import useIsDesktop from 'lib/useIsDesktop'

import * as s from './DataPieChart.module.scss'
const cn = classNames.bind(s)

const Label = ({ children, ...props }) => {
  const isDesktop = useIsDesktop()
  const factor = useMemo(() => (isDesktop ? 4 : 5), [isDesktop])
  const cleaned = { ...props }
  delete cleaned.dataEntry
  delete cleaned.dataIndex

  return (
    <text {...cleaned} textAnchor='start' className={cn('label')}>
      {rows(children).map((row: string, index: number) => (
        <tspan
          {...cleaned}
          x={props.x - 5}
          y={index === 0 ? props.y : props.y + factor * index}
          textAnchor='start'
          key={`span-${index}`}
        >
          {row}
        </tspan>
      ))}
    </text>
  )
}

export default Label

function rows(str: string, length = 15) {
  return str.split('').reduce(
    function (rows, char) {
      const last = rows.length - 1
      if (rows[last].length < length || char === ' ') {
        rows[last] += char
      } else if (char !== ' ') {
        const tail = rows[last].match(/\S+?$/)
        if (tail) {
          rows[last] = rows[last].substr(0, tail.index)
          char = tail[0] + char
        }
        rows.push(char)
      }
      return rows
    },
    [''],
  )
}
