import React from 'react'
import { RichText } from 'prismic-reactjs'
import classNames from 'classnames/bind'

import { htmlSerializerLight } from 'prismic/htmlSerializer'
import { Row, Column } from 'components/core/Grid'
import Container from 'components/core/Container'
import { MonumentSubtitle } from 'components/ui/Text'
import Spacer from 'components/core/Spacer'

import { TextSectionProps } from './TextSectionTypes'
import * as s from './TextSection.module.scss'
const cn = classNames.bind(s)

const TextSection = ({ data }: TextSectionProps) => (
  <Container as='section'>
    <Row>
      <Column initial={6} medium={1} />
      <Column initial={6} medium={3}>
        <MonumentSubtitle className={cn('title')} as='h2'>
          {data.section_title?.text}
        </MonumentSubtitle>
        <Spacer initial='0.4vw' medium='0.4vw' />
        {data.section_text?.raw && (
          <div className={cn('description')}>
            <RichText render={data.section_text.raw} htmlSerializer={htmlSerializerLight()} />
          </div>
        )}
        <Spacer initial='12.5vw' medium='8.33vw' />
      </Column>
      <Column initial={6} medium={2} />
    </Row>
  </Container>
)

export default TextSection
