import React, { useState } from 'react'
import classNames from 'classnames/bind'
import useLayoutEffect from '@react-hook/passive-layout-effect'

import ViewportEnter from 'components/core/ViewportEnter'

import { ViewportEnterEffectType } from './ViewportEnterEffectTypes'
// import s from './ViewportEnterEffect.module.scss'
import * as s from './ViewportEnterEffect.module.scss'

const cn = classNames.bind(s)

const ViewportEnterEffect: React.FC<ViewportEnterEffectType> = ({
  children,
  effect = 'fade',
  delayMs = 0,
  once = true,
  fit = false,
  mask = false,
  disabled = false,
  fillHeight = false,
  threshold,
  className = '',
}) => {
  const [visible, setVisible] = useState<boolean>(false)
  const [ready, setReady] = useState<boolean>(false)

  const inlineStyles = { animationDelay: `${delayMs}ms` }

  useLayoutEffect(() => {
    setReady(true)
  }, [])

  if (disabled) {
    return <>{children}</>
  }

  return (
    <ViewportEnter
      onEnter={() => setVisible(true)}
      onExit={() => (once ? null : setVisible(false))}
      threshold={threshold}
      once={once}
    >
      <div
        style={{ visibility: ready ? 'visible' : 'hidden' }}
        className={cn(s.effectWrapper, className, { fit, mask, fillHeight })}
      >
        <div
          style={inlineStyles}
          className={cn(s[effect], s.effect, {
            visible,
          })}
        >
          {children}
        </div>
      </div>
    </ViewportEnter>
  )
}

export default ViewportEnterEffect
