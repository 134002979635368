// extracted by mini-css-extract-plugin
export var colorBlack = "#000";
export var colorGray = "gray";
export var colorDarkGray = "#262626";
export var colorLightGray = "#e5e5e5";
export var colorWhite = "#fff";
export var colorElectricBlue = "#0000fe";
export var colorPastelBlue = "#bbe5fe";
export var colorCandyPink = "#f9709a";
export var colorPastelPink = "#ffcdd2";
export var colorDefaultLime = "#cef564";
export var colorPastelLime = "#eeffbe";
export var colorBlueLagoon = "#9cf0e1";
export var colorPastelLagoon = "#ceffec";
export var colorDefaultLavender = "#8738f6";
export var colorPastelLavender = "#eadcff";
export var colorBrightYellow = "#ffe500";
export var colorDefaultGreen = "#12b968";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1024px";
export var breakpointLarge = "1440px";
export var colCount = "6";
export var colWidth = "16.6666666667%";
export var relative = "Takeaway-module--relative--ERZDK";
export var illustration = "Takeaway-module--illustration--3xhKp";
export var container = "Takeaway-module--container--2JOcf";
export var inner = "Takeaway-module--inner--1t0iL";
export var textContainer = "Takeaway-module--textContainer--2CMC-";
export var tag = "Takeaway-module--tag--iE8Tt";
export var sourcesContainer = "Takeaway-module--sourcesContainer--1rPtS";
export var sources = "Takeaway-module--sources--xoUP6";
export var sourcesLink = "Takeaway-module--sourcesLink--1Ids4";
export var popup = "Takeaway-module--popup--p2Pl6";
export var meta = "Takeaway-module--meta--1tZLw";
export var date = "Takeaway-module--date --3eQkI";
export var image = "Takeaway-module--image--xE7xn";
export var source = "Takeaway-module--source--3K4wa";