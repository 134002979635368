import React, { useState, useMemo, useCallback } from 'react'
import classNames from 'classnames/bind'
import atob from 'atob'

import useIsDesktop from 'lib/useIsDesktop'
import ViewportEnter from 'components/core/ViewportEnter'
import Container from 'components/core/Container'

import * as s from './ShortExplanationPills.module.scss'
import { ShortExplanationPillsProps } from './ShortExplanationPillsTypes'

const cn = classNames.bind(s)

const getDecodedData = (str: string) => {
  const dataStr = 'data:image/svg+xml;base64,'
  return str.slice(str.indexOf(dataStr) + dataStr.length, str.length)
}

const ShortExplanationPills = ({ data }: ShortExplanationPillsProps) => {
  const [isInView, setIsInView] = useState(false)
  const isDesktop = useIsDesktop()
  const html = useMemo(() => {
    /* Exit early if no image exists */
    if (!data?.image?.fixed.base64) return ''

    const base64 = data.image.fixed.base64
    const base64Mobile = data.image.thumbnails?.mobile?.fixed.base64 || base64
    const _b64data = isDesktop ? base64 : base64Mobile
    if (!_b64data.startsWith('data:image/svg+xml;base64'))
      return `<img src="${_b64data}" alt='preview svg' width="100%" />`
    return atob(getDecodedData(_b64data))
  }, [data.image, isDesktop])

  const handleClick = useCallback(() => {
    setIsInView(false)
    setTimeout(() => setIsInView(true), 0)
  }, [])

  return (
    <Container>
      <ViewportEnter onEnter={() => setIsInView(true)} threshold={0.1}>
        <button
          className={cn('inner')}
          onClick={handleClick}
          {...(data?.image?.alt && { 'aria-label': data.image.alt })}
        >
          <div className={cn('media', { isInView })} dangerouslySetInnerHTML={{ __html: html }} />
        </button>
      </ViewportEnter>
    </Container>
  )
}

export default ShortExplanationPills
