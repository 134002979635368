import React, { useState, useMemo, useLayoutEffect } from 'react'
import { RichText } from 'prismic-reactjs'
import classNames from 'classnames/bind'
import { GatsbyImage } from 'gatsby-plugin-image'

import htmlSerializer, { interactiveHighlightSerializer } from 'prismic/htmlSerializer'
import CrossFadeContainer from 'components/core/CrossFadeContainer'
import Popup from 'components/ui/Popup'
import Spacer from 'components/core/Spacer'
import LottieAnimation from 'components/core/LottieAnimation'
import useUIContext from 'context/ui'

import * as s from './DataVizGeneric.module.scss'
import { DataVizGenericProps } from './DataVizGenericTypes'

const cn = classNames.bind(s)

const DataVizGeneric = ({ data, items }: DataVizGenericProps) => {
  const [open, setOpen] = useState<number | false>(false)
  const [showAnimation, setShowAnimation] = useState(false)
  const isFactPageScrollable = useUIContext(s => s.isFactPageScrollable)

  useLayoutEffect(() => {
    if (isFactPageScrollable) setShowAnimation(true)
  }, [isFactPageScrollable])

  const itemsMobile = useMemo(() => {
    return items.map(i => ({ ...i, layout: data.layout }))
  }, [data.layout, items])

  const itemsDesktop = useMemo(
    () =>
      items.map((item, index) => ({
        layout: data.layout.replace('2 Column ', ''),

        animation: item.animation?.url ? (
          <div className={cn('relative')}>
            <div className={cn('imageContainer')}>
              {showAnimation && <LottieAnimation json={item.animation.url} className={cn('image')} />}
            </div>
          </div>
        ) : null,

        image: item.image?.gatsbyImageData ? (
          <div className={cn('relative')}>
            <div className={cn('imageContainer')}>
              <GatsbyImage
                objectFit='contain'
                className={cn('image')}
                image={item.image.gatsbyImageData}
                alt={item.image.alt || ''}
              />
            </div>
          </div>
        ) : null,

        text: item.text?.raw ? (
          <div className={cn('textContainer')}>
            <RichText
              render={item.text.raw}
              htmlSerializer={interactiveHighlightSerializer((open: boolean) => {
                if (open) setOpen(index)
                else setOpen(false)
              }, data?.highlight_color)}
            />
            {item.text_extended?.raw && (
              <>
                <Spacer initial='0' medium='1vw' />
                <RichText render={item.text_extended.raw} htmlSerializer={htmlSerializer} />
              </>
            )}
            {item.popup && (
              <Popup className={cn('popup')} open={index === open} onClose={() => setOpen(false)}>
                {item.popup}
              </Popup>
            )}
          </div>
        ) : null,
      })),
    [items, data.layout, data?.highlight_color, showAnimation, open],
  )

  return <CrossFadeContainer numberOfItems={items.length} itemsMobile={itemsMobile} itemsDesktop={itemsDesktop} />
}

export default DataVizGeneric
