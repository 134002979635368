import React from 'react'
import classNames from 'classnames/bind'

import Spacer from 'components/core/Spacer'
import { Row, Column } from 'components/core/Grid'
import Container from 'components/core/Container'
import { MonumentSubtitle } from 'components/ui/Text'

import { StepsProps } from './StepsTypes'
import Step from './Step'
import * as s from './Steps.module.scss'
const cn = classNames.bind(s)

const Steps = ({ data, items }: StepsProps) => (
  <Container className={cn('container')} as='section'>
    <Spacer initial='14.6vw' medium='5.96vw' />
    <Row>
      <Column initial={6} medium={1} />
      <Column initial={6} medium={4}>
        <MonumentSubtitle className={cn('title')} as='h2'>
          {data.steps_title?.text}
        </MonumentSubtitle>
        <Spacer initial='12.8vw' medium='2.68vw' />
        <Row className={cn('innerRow')} spacing='wide'>
          {items.map((item, index) => (
            <Step key={`item-${index}`} item={item} index={index} />
          ))}
        </Row>
        <Spacer initial='10vw' medium='8.33vw' />
      </Column>
      <Column initial={6} medium={1} />
    </Row>
  </Container>
)

export default Steps
