import React, { useState, useCallback } from 'react'
import { easePolyIn, easePolyOut } from 'd3-ease'
import classNames from 'classnames/bind'
import { useMotionValue, AnimatePresence, motion } from 'framer-motion'

import { Row, Column } from 'components/core/Grid'
import Container from 'components/core/Container'
import ScrollParallax from 'components/core/ScrollParallax'

import MotionValueContext from './MotionValueContext'
import { ItemTypes } from './StickyItemsTypes'
import * as s from './StickyItems.module.scss'

const cn = classNames.bind(s)

const CONTAINER_THRESHOLD = 0.019
const TEXT_CONTAINER_MIN_THRESHOLD = 0.019
const TEXT_CONTAINER_MAX_THRESHOLD = 0.868

const variants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.2,
    },
  },
}

const Item = ({
  index,
  image,
  animation,
  text,
  layout,
  scrollY,
  onChange,
  isActive,
  keyframes,
  times,
  startOffset,
  stopOffset,
  fixedFadeInOnText /* if true, the opacity of the text container is handled by a css transition */,
}: ItemTypes) => {
  const [visible, setVisible] = useState(index === 0 ? true : false)
  const [textVisible, setTextVisible] = useState(false)
  const motionValue = useMotionValue(0)

  const column = animation ? (
    <Column initial={6} medium={3} className={cn('image', { animate: index !== 0 })}>
      <AnimatePresence exitBeforeEnter>
        {isActive && (
          <motion.div variants={variants} initial='initial' animate='animate' exit='exit'>
            {animation}
          </motion.div>
        )}
      </AnimatePresence>
    </Column>
  ) : image ? (
    <Column
      initial={6}
      medium={3}
      className={cn('image', { animate: index !== 0 })}
      {...(index !== 0 && {
        style: {
          opacity: visible ? 1 : 0,
        } as React.CSSProperties,
      })}
    >
      {image}
    </Column>
  ) : (
    <Column initial={6} medium={3} />
  )

  const handleChange = useCallback(
    (progress: number) => {
      let next = visible
      if (progress > CONTAINER_THRESHOLD && !visible) next = true
      else if (progress < CONTAINER_THRESHOLD && visible) next = false

      /* Handle image visibility */
      if (visible !== next) {
        setVisible(next)

        if (!onChange) return

        /*
         *   onChange is used to update outside sibling fullscreen image
         * use `setTimeout` to delay external update,
         * avoiding to update both `Item` and `ScrollParallax` components at the same time
         */
        const val = next === false ? index - 1 : index

        setTimeout(() => onChange(val), 0)
      }

      if (progress > TEXT_CONTAINER_MIN_THRESHOLD && progress < TEXT_CONTAINER_MAX_THRESHOLD && !textVisible) {
        setTextVisible(true)
      } else if (
        (progress > TEXT_CONTAINER_MAX_THRESHOLD && textVisible) ||
        (progress < TEXT_CONTAINER_MIN_THRESHOLD && textVisible)
      ) {
        setTextVisible(false)
      }

      motionValue.set(progress)
    },
    [visible, textVisible, index, onChange, motionValue],
  )

  return (
    <section className={cn('section')} style={{ pointerEvents: visible ? 'auto' : 'none' }}>
      <Container>
        <Row>
          {/* IMAGE – positioned left (text to the right) */}
          {layout === 'Right' && column}
          <Column initial={6} medium={3}>
            {/* PARALLAX TEXT */}
            <ScrollParallax
              motionValue={scrollY}
              keyframes={keyframes}
              times={times}
              startOffset={startOffset}
              stopOffset={stopOffset}
              ease={{
                y: [easePolyOut.exponent(1.15), easePolyIn.exponent(1.15)],
                opacity: [easePolyOut.exponent(1.15), easePolyOut.exponent(1.15), easePolyIn.exponent(1.15)],
              }}
              onChange={handleChange}
            >
              <div
                className={cn('text', { isTransitioning: fixedFadeInOnText })}
                {...(fixedFadeInOnText && { style: { opacity: textVisible ? 1 : 0 } })}
              >
                <div className={cn('inner')}>
                  {/* @ts-ignore | Wrap `text` to be able to use motionValue when needed */}
                  <MotionValueContext.Provider value={{ motionValue }}>{text}</MotionValueContext.Provider>
                </div>
              </div>
            </ScrollParallax>
          </Column>
          {/* IMAGE – positioned right (text to the left) */}
          {layout === 'Left' && column}
        </Row>
      </Container>
    </section>
  )
}

export default Item
