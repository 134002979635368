import React from 'react'
import classNames from 'classnames/bind'

import Love from 'assets/svg/love.react.svg'
import Empowered from 'assets/svg/empowered.react.svg'
import Sad from 'assets/svg/sad.react.svg'
import Hopeful from 'assets/svg/hopeful.react.svg'
import love from 'assets/lottie/love.json'
import empowered from 'assets/lottie/empowered.json'
import sad from 'assets/lottie/sad.json'
import hopeful from 'assets/lottie/hopeful.json'

import * as s from './Reactions.module.scss'
import { Item } from './ReactionsTypes'
const cn = classNames.bind(s)

const Items: Item[] = [
  {
    icon: <Love className={cn('icon')} />,
    json: love,
    id: 'love',
  },
  {
    icon: <Empowered className={cn('icon')} />,
    json: empowered,
    id: 'empowered',
  },
  {
    icon: <Sad className={cn('icon')} />,
    json: sad,
    id: 'sad',
  },
  {
    icon: <Hopeful className={cn('icon')} />,
    json: hopeful,
    id: 'hopeful',
  },
]

export default Items
