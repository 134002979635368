import React from 'react'
import classNames from 'classnames/bind'
import { GatsbyImage } from 'gatsby-plugin-image'

import getPositionClass from 'lib/getPositionClass'

import { IllustrationProps } from './IllustrationTypes'
import * as s from './Illustration.module.scss'

const cn = classNames.bind(s)

const Illustration = ({ image, position, landscape, sticky }: IllustrationProps) => {
  return (
    <GatsbyImage
      image={image.gatsbyImageData}
      alt={''}
      style={{ position: sticky ? 'sticky' : 'absolute' }}
      objectFit='contain'
      className={cn('image', position && `${getPositionClass(position)}`, { landscape })}
    />
  )
}

export default Illustration
