import { easeQuadOut } from 'd3-ease'

export const variants = {
  animate: {
    y: '-190%',
    x: '-50%',
    opacity: 1,
    transition: {
      y: {
        type: 'spring',
        stiffness: 170,
      },
    },
  },
  exit: {
    y: '-120%',
    x: '-50%',
    opacity: 0,
    transition: {
      y: {
        type: 'spring',
        stiffness: 170,
      },
    },
  },
}

const DEFAULT_DURATION = 1.2
const DEFAULT_TRANSITION = { ease: easeQuadOut, duration: DEFAULT_DURATION }
export const floatingAnimation = {
  y: ['100%', '-100%'],
  x: ['0%', '-10%', '10%', '-10%', '10%', '0%'],
  scale: [0, 1, 0.8, 0.3],
  opacity: [1, 1, 0.5, 0],
  transition: {
    y: DEFAULT_TRANSITION,
    scale: DEFAULT_TRANSITION,
    opacity: DEFAULT_TRANSITION,
    x: { ease: 'linear', duration: DEFAULT_DURATION },
  },
}

export const scaleUpAnimation = { scale: [1.4, 2, 1.4], transition: { type: 'spring', duration: 0.4 } }
export const fallDownAnimation = { scale: [1.4, 1], y: ['-40%', '0%'], transition: { type: 'spring', duration: 0.2 } }
export const bounceOnHoverAnimation = { y: '-40%', scale: 1.4, transition: { type: 'spring', duration: 0.6 } }
