import React, { useRef, useCallback, useState } from 'react'
import classNames from 'classnames/bind'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { AnimatePresence, motion } from 'framer-motion'

import Container from 'components/core/Container'
import { Row, Column } from 'components/core/Grid'
import { CircularBodyLarge, CircularHeading4 } from 'components/ui/Text'
import ViewportEnter from 'components/core/ViewportEnter'

import Revealer from './Revealer'
import { ExampleDragAndRevealTypes } from './ExampleDragAndRevealTypes'
import { contentVariants } from './variants'
import * as s from './ExampleDragAndReveal.module.scss'
const cn = classNames.bind(s)

const ExampleDragAndReveal = ({ data }: ExampleDragAndRevealTypes) => {
  const content = [
    {
      hightlight: data.start_highlight_color,
      title: data.start_title.text,
      text: data.start_text.text,
    },
    {
      hightlight: data.end_highlight_color,
      title: data.end_title.text,
      text: data.end_text.text,
    },
  ]

  const images = [
    {
      image: getImage(data.start_image.gatsbyImageData) || null,
      alt: data.start_image.alt || '',
    },
    { image: getImage(data.end_image.gatsbyImageData) || null, alt: data.end_image.alt || '' },
  ]

  const constraintsRef = useRef<HTMLDivElement>(null)
  const wrapper = useRef<HTMLDivElement>(null)
  const [activeIndex, setActiveIndex] = useState(0)
  const [isInView, setIsInView] = useState(false)

  const updateIndex = useCallback(
    (progress: number) => {
      if ((activeIndex === 0 && progress < 0.5) || (activeIndex === 1 && progress >= 0.5)) return
      setActiveIndex(progress > 0.5 ? 1 : 0)
    },
    [activeIndex],
  )

  const updateProgress = useCallback(
    (progress, x) => {
      if (!wrapper.current) return
      wrapper.current.style.setProperty('--progress', progress)
      wrapper.current.style.setProperty('--x', x)
      updateIndex(progress)
    },
    [updateIndex],
  )

  return (
    <div className={cn('dragAndReveal', { isInView })} ref={wrapper}>
      <div className={cn('inner')}>
        <Container>
          <Row>
            <Column initial={0} medium={1} />
            <Column initial={6} medium={2} className={cn('contentWrapper')}>
              <AnimatePresence exitBeforeEnter>
                <motion.div
                  key={activeIndex}
                  className={cn('content')}
                  variants={contentVariants}
                  initial='initial'
                  animate='animate'
                  exit='exit'
                  custom={activeIndex}
                >
                  <div className={cn('title')}>
                    <CircularHeading4 as='h3'>{content[activeIndex].title}</CircularHeading4>
                  </div>
                  <div className={cn('text')}>
                    <CircularBodyLarge>{content[activeIndex].text}</CircularBodyLarge>
                  </div>
                </motion.div>
              </AnimatePresence>
            </Column>
            <Column initial={6} medium={3}>
              <ViewportEnter onEnter={() => setIsInView(true)}>
                <div className={cn('revealerWrapper')}>
                  <div ref={constraintsRef} className={cn('constraintsRef')} />
                  {images.map((el, i) => (
                    <div key={`image${i}`} className={cn('image', `image${i + 1}`)}>
                      {el.image && <GatsbyImage image={el.image} alt={el.alt} />}
                    </div>
                  ))}
                  <Revealer
                    ref={constraintsRef}
                    offset={data.initial_reveal_offset || 50}
                    updateProgress={updateProgress}
                  />
                </div>
              </ViewportEnter>
            </Column>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default ExampleDragAndReveal
