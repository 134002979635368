// extracted by mini-css-extract-plugin
export var colorBlack = "#000";
export var colorGray = "gray";
export var colorDarkGray = "#262626";
export var colorLightGray = "#e5e5e5";
export var colorWhite = "#fff";
export var colorElectricBlue = "#0000fe";
export var colorPastelBlue = "#bbe5fe";
export var colorCandyPink = "#f9709a";
export var colorPastelPink = "#ffcdd2";
export var colorDefaultLime = "#cef564";
export var colorPastelLime = "#eeffbe";
export var colorBlueLagoon = "#9cf0e1";
export var colorPastelLagoon = "#ceffec";
export var colorDefaultLavender = "#8738f6";
export var colorPastelLavender = "#eadcff";
export var colorBrightYellow = "#ffe500";
export var colorDefaultGreen = "#12b968";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1024px";
export var breakpointLarge = "1440px";
export var colCount = "6";
export var colWidth = "16.6666666667%";
export var container = "Steps-module--container--3S10f";
export var title = "Steps-module--title--2w-RW";
export var innerRow = "Steps-module--innerRow--3DQgP";
export var innerColumn = "Steps-module--innerColumn--365MO";
export var isInView = "Steps-module--isInView--11Ocz";
export var card = "Steps-module--card--2Xehk";
export var image = "Steps-module--image--3Z3b6";
export var sub = "Steps-module--sub--15SPh";
export var cta = "Steps-module--cta--1gIMb";
export var link = "Steps-module--link--2xRGn";