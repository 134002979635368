import create from 'zustand'

export interface Source {
  source_label: string
  source_url: {
    url: string
  }
}

interface Store {
  sources: Source[]
  setSources: (sources: Source[]) => void
  lastPublicationDate: string
  setLastPublicationDate: (date: string) => void
  author: string
  setAuthor: (author: string) => void
}

const useSourcesContext = create<Store>(set => ({
  sources: [],
  setSources: sources => set({ sources }),
  lastPublicationDate: '',
  setLastPublicationDate: lastPublicationDate => set({ lastPublicationDate }),
  author: '',
  setAuthor: author => set({ author }),
}))

export default useSourcesContext
