import React, { useState, useMemo, useRef, useEffect } from 'react'
import { RichText } from 'prismic-reactjs'
import classNames from 'classnames/bind'
import { PieChart } from 'react-minimal-pie-chart'
import { useWindowSize } from '@react-hook/window-size'

import htmlSerializer from 'prismic/htmlSerializer'
import { CircularHeading4 } from 'components/ui/Text'
import useIsDesktop from 'lib/useIsDesktop'
import { Row, Column } from 'components/core/Grid'
import Container from 'components/core/Container'
import Spacer from 'components/core/Spacer'
import Popup from 'components/ui/Popup'

import Label from './Label'
import * as s from './DataPieChart.module.scss'
import { DataPieChartProps, Dimensions, PopupPosition } from './DataPieChartTypes'

const cn = classNames.bind(s)

const DataPieChart = ({ data, items }: DataPieChartProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const [width] = useWindowSize({ wait: 200 })
  const isDesktop = useIsDesktop()
  const [dimensions, setDimensions] = useState<Dimensions | null>(null)
  const [popupPosition, setPopupPosition] = useState<PopupPosition | null>(null)

  useEffect(() => {
    const obj = ref.current?.getBoundingClientRect()
    if (!obj) return
    setDimensions({ width: obj.width, height: obj.height, x: obj.x, y: obj.y })
  }, [width])

  const [active, setActive] = useState<number | null>(null)
  const segments = useMemo(() => {
    return items.map(item => {
      return {
        title: item.title || '',
        value: item.value || 0,
        color: 'var(--theme-secondary)',
      }
    })
  }, [items])

  const shiftSize = 5
  const rightAlignData = data.layout === '2 Column Left'

  // Create multiple pies to add the illusion of a border and shadow
  const pies = ['first', 'border', 'shadow', 'reference']

  const popupContent = items[active || 0]?.pie_popup?.raw

  return (
    <Container className={cn('container')}>
      <Row className={cn('content', { rightAlignData })}>
        <Column initial={6} medium={3}>
          <div aria-label={data?.accessibility_label} role='img' className={cn('srOnly')} />
          <div className={cn('pieContainer')} ref={ref} aria-hidden='true'>
            {pies.map((pie, index) => (
              <PieChart
                key={`pie-${index}--${active}`} // Trigger re-render when active change to avoid buggy behavior in Safari
                className={cn('pie', pie)}
                startAngle={data.start_angle || 0}
                radius={PieChart.defaultProps.radius - shiftSize * 1.5}
                segmentsShift={index => (index === active && pie !== 'reference' ? shiftSize : 0.1)}
                data={segments}
                {...(isDesktop
                  ? {
                      onMouseOver: (_e, index) => {
                        if (!dimensions) return

                        setPopupPosition({
                          left: _e.clientX < dimensions?.width / 2 + dimensions?.x,
                          top: _e.clientY < dimensions?.height / 2 + dimensions?.y,
                        })
                        setActive(index)
                      },
                      onMouseOut: () => setActive(null),
                    }
                  : {
                      onClick: (_e, index) => setActive(active === index ? null : index),
                    })}
                {...(index === 0
                  ? {
                      labelStyle: {
                        pointerEvents: 'none',
                      },
                      label: function renderLabel(props) {
                        return (
                          <Label key={segments[props.dataIndex].title} {...props}>
                            {segments[props.dataIndex].title}
                          </Label>
                        )
                      },
                      labelPosition: 58,
                    }
                  : {})}
              />
            ))}

            {popupContent && (
              <Popup
                open={active !== null ? true : false}
                containerClassName={cn('popupContainer', { left: popupPosition?.left, top: popupPosition?.top })}
                className={cn('popup')}
                onClose={() => setActive(null)}
                translateX='-50%'
                translateY='-50%'
              >
                <RichText render={popupContent} htmlSerializer={htmlSerializer} />
              </Popup>
            )}
          </div>
        </Column>
        <Column initial={6} medium={3} className={cn('text')}>
          <div className={cn('textContent')}>
            {data.text && <RichText render={data.text.raw} htmlSerializer={htmlSerializer} />}
            {data.text_extended?.text && (
              <>
                <Spacer initial='0' medium='1vw' />
                <CircularHeading4 as='p' className={cn('extended')}>
                  {data.text_extended.text}
                </CircularHeading4>
              </>
            )}
          </div>
        </Column>
      </Row>
    </Container>
  )
}

export default DataPieChart
