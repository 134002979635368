import React from 'react'
import classNames from 'classnames/bind'
import { Elements } from 'prismic-richtext'
import { RichText } from 'prismic-reactjs'

import Highlight from 'components/ui/Highlight'
import htmlSerializer from 'prismic/htmlSerializer'
import Interactable from 'components/core/Interactable'
import Popup from 'components/ui/Popup'

import * as s from './WorldMap.module.scss'
const cn = classNames.bind(s)

const serializer = (popupContent, isOpen, onInteraction) => {
  // eslint-disable-next-line react/display-name
  return (type, _element, _content, children, key) => {
    switch (type) {
      /*
       * `strong` is used into highlight word.
       */
      case Elements.strong:
        return (
          <span className={cn('highlight')} key={key}>
            <Popup open={isOpen} onClose={() => onInteraction(false)}>
              {popupContent}
            </Popup>

            <Interactable
              onClick={() => onInteraction(true)}
              onEnter={() => onInteraction(true)}
              onLeave={() => onInteraction(false)}
            >
              <Highlight>{children}</Highlight>
            </Interactable>

            {popupContent?.raw && <RichText render={popupContent.raw} htmlSerializer={htmlSerializer} />}
          </span>
        )

      default:
        return null
    }
  }
}

export default serializer
