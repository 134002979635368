export const contentVariants = {
  initial: (activeIndex: number) => ({
    y: activeIndex == 0 ? '-10%' : '10%',
    opacity: 0,
  }),
  animate: {
    y: '0%',
    opacity: 1,
    transition: {
      duration: 0.4,
      ease: 'easeInOut',
    },
  },
  exit: (activeIndex: number) => ({
    y: activeIndex == 0 ? '-10%' : '10%',
    opacity: 0,
    transition: {
      duration: 0.4,
      ease: 'easeInOut',
    },
  }),
}
