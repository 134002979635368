import React, { useState, useRef, useEffect } from 'react'
import classNames from 'classnames/bind'
import { GatsbyImage } from 'gatsby-plugin-image'

import { Row, Column } from 'components/core/Grid'
import Container from 'components/core/Container'
import ViewportEnter from 'components/core/ViewportEnter'

import * as s from './DataVizTimeline.module.scss'
import { UnmaskedChildrenProps } from './DataVizTimelineTypes'

const cn = classNames.bind(s)
const DURATION_FACTOR = 25

const UnmaskedChildren = ({ data, layout, progress }: UnmaskedChildrenProps) => {
  const wrapper = useRef<HTMLDivElement>(null)
  const [isInView, setIsInView] = useState(false)

  useEffect(() => {
    if (!wrapper.current || !isInView) return

    /* Base transition duration on diff in from/to values */
    const from = wrapper.current.style.getPropertyValue('--progress')
    let diff = progress - parseFloat(from)
    if (diff < 0) diff *= -1

    wrapper.current.style.setProperty('--progress', `${progress}`)
    wrapper.current.style.setProperty('--transition-duration', `${Math.max(diff * 100 * DURATION_FACTOR, 1200)}ms`)
  }, [progress, isInView])

  return (
    <Container>
      <Row>
        <Column initial={6} medium={3} className={cn('column', { leftAlignText: layout === 'Left' })}>
          {data.background_layer && data.foreground_layer && (
            <ViewportEnter onEnter={() => setIsInView(true)} threshold={0.5}>
              <div
                className={cn('imageContainer', { isInView })}
                ref={wrapper}
                style={{ '--progress': '0' } as React.CSSProperties}
              >
                <GatsbyImage
                  className={cn('image')}
                  image={data.background_layer.gatsbyImageData}
                  alt={data.background_layer.alt || ''}
                  objectFit='contain'
                />
                <div className={cn('foreground', 'outer')}>
                  <div className={cn('foreground', 'inner')}>
                    <GatsbyImage
                      className={cn('image')}
                      image={data.foreground_layer.gatsbyImageData}
                      alt={data.foreground_layer.alt || ''}
                      objectFit='contain'
                    />
                  </div>
                </div>
              </div>
            </ViewportEnter>
          )}
        </Column>
      </Row>
    </Container>
  )
}

export default UnmaskedChildren
