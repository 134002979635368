import React from 'react'
import classNames from 'classnames/bind'

import Illustration from 'components/ui/Illustration'
import useIsDesktop from 'lib/useIsDesktop'

import { Item } from './TakeawayTypes'
import * as s from './Takeaway.module.scss'

const cn = classNames.bind(s)
const largeBreakpoint = parseInt(s.breakpointDesktop)

const TakeawayIllustrations = ({ items, position }: { items: Item[]; position?: string }) => {
  const isDesktop = useIsDesktop(largeBreakpoint)

  if (!items || items.length === 0) return null

  return (
    <>
      {items.map((item, index) => {
        if (!item?.image?.gatsbyImageData) return null

        const image = !isDesktop && item?.mobile_image?.dimensions ? item?.mobile_image : item?.image
        const landscape = image.dimensions.width / image.dimensions.height > 1

        return (
          <div className={cn('illustration')} key={`illustration-${index}`}>
            <Illustration
              image={image}
              position={item.position}
              landscape={landscape || false}
              sticky={position === 'sticky'}
            />
          </div>
        )
      })}
    </>
  )
}

export default TakeawayIllustrations
