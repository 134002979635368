import React, { useState } from 'react'
import { RichText } from 'prismic-reactjs'

import htmlSerializer from 'prismic/htmlSerializer'
import Popup from 'components/ui/Popup'

import Sticker from './Sticker'

import { StickersProps } from './StickersTypes'

const Stickers = ({ items }: StickersProps) => {
  const [popupOpen, setPopupOpen] = useState<number | null>(null)

  return (
    <>
      {items.map((i, index: number) => (
        <Sticker
          len={items.length}
          key={`sticker-${index}`}
          hasPopup={!!i.popup_text && !!i.popup_text.raw.length}
          onEnter={() => setPopupOpen(index)}
          onLeave={() => setPopupOpen(null)}
          onClick={() => setPopupOpen(index)}
          item={i}
        />
      ))}
      {items.map((popup, index) => {
        return (
          <Popup
            open={popupOpen === index}
            key={`popup-${index}`}
            onClose={() => setPopupOpen(null)}
            left='50%'
            top='50%'
            translateX='-50%'
            translateY='-50%'
            {...(popup?.popup_text?.text && { ariaLabel: popup.popup_text.text })}
          >
            {popup?.popup_text?.raw && <RichText render={popup.popup_text.raw} htmlSerializer={htmlSerializer} />}
          </Popup>
        )
      })}
    </>
  )
}

export default Stickers
