import React, { useRef } from 'react'
import { Canvas } from '@react-three/fiber'
import { ResizeObserver } from '@juggle/resize-observer'

import MapSVG from './MapSVG'
import GlobeMesh from './GlobeMesh'
import { GlobeCanvasProps } from '../Data3DGlobe/Data3DGlobeTypes'

export default function GlobeCanvas({
  countries,
  lat,
  lng,
  className,
  style,
  showCountries = true,
  fullWindowParallax,
  ...props
}: GlobeCanvasProps) {
  const svgRef = useRef<HTMLDivElement>(null)
  // Only renderer client-side so accessing document is safe here
  const color = showCountries
    ? getComputedStyle(document.documentElement).getPropertyValue('--theme-secondary').trim()
    : ''
  const bg = getComputedStyle(document.documentElement).getPropertyValue('--theme-background').trim()

  return (
    <>
      <MapSVG showCountries={showCountries} ref={svgRef} />
      <div className={className} aria-hidden='true'>
        <Canvas dpr={[1, 2]} flat frameloop='demand' style={style} resize={{ polyfill: ResizeObserver }}>
          <GlobeMesh
            fullWindowParallax={fullWindowParallax}
            textureSource={svgRef}
            countries={countries}
            lat={lat}
            lng={lng}
            color={color}
            bg={bg}
            showCountries={showCountries}
            {...props}
          />
        </Canvas>
      </div>
    </>
  )
}
