import React, { useState, useLayoutEffect } from 'react'
import { RichText } from 'prismic-reactjs'
import classNames from 'classnames/bind'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import useUIContext from 'context/ui'
import htmlSerializer, { interactiveHighlightSerializer } from 'prismic/htmlSerializer'
import { Row, Column } from 'components/core/Grid'
import Container from 'components/core/Container'
import Spacer from 'components/core/Spacer'
import Popup from 'components/ui/Popup'
import LottieAnimation from 'components/core/LottieAnimation'

import * as s from './TwoColumn.module.scss'
import { TwoColumnProps } from './TwoColumnTypes'

const cn = classNames.bind(s)

const TwoColumn = ({ data, children }: TwoColumnProps) => {
  const [open, setOpen] = useState(false)
  const [showAnimation, setShowAnimation] = useState(false)

  const isFactPageScrollable = useUIContext(s => s.isFactPageScrollable)

  const rightAlignImage = data.layout === '2 Column Left'

  let image = null
  if (data.image) {
    if (React.isValidElement(data.image)) {
      image = data.image
    } else {
      const el = getImage(data.image.gatsbyImageData)
      if (el) image = <GatsbyImage image={el} alt={data?.image?.alt || ''} />
    }
  }

  useLayoutEffect(() => {
    if (isFactPageScrollable) setShowAnimation(true)
  }, [isFactPageScrollable])

  return (
    <Container className={cn('container')}>
      <Row className={cn('content', { rightAlignImage })}>
        <Column initial={6} medium={3} className={cn('image')}>
          {data?.animation?.url && showAnimation ? (
            <>
              <span aria-hidden='true'>
                <LottieAnimation json={data?.animation?.url} className={cn('animation')} />
              </span>
              <div className={cn('srOnly')} role='img' aria-label={data?.animation_aria_label} />
            </>
          ) : (
            image
          )}
        </Column>

        <Column initial={6} medium={3} className={cn('text')}>
          <div className={cn('textContent')}>
            {data.text && (
              <div style={{ position: 'relative' }}>
                {children && children}
                <RichText
                  render={data.text.raw}
                  htmlSerializer={interactiveHighlightSerializer(
                    (open: boolean) => setOpen(open),
                    data?.highlight_color,
                  )}
                />

                <Popup open={open} onClose={() => setOpen(false)}>
                  {data.popup}
                </Popup>
              </div>
            )}
            {data.text_extended && (
              <>
                <Spacer initial='0' medium='1vw' hideOnMobile />
                <RichText render={data.text_extended.raw} htmlSerializer={htmlSerializer} />
              </>
            )}
          </div>
        </Column>
      </Row>
    </Container>
  )
}

export default TwoColumn
