import React, { useState, useLayoutEffect } from 'react'
import classNames from 'classnames/bind'

import StickyItems from 'components/core/StickyItems'
import TwoColumn from 'components/ui/TwoColumn'
import useIsDesktop from 'lib/useIsDesktop'

import { CrossFadeContainerProps } from './CrossFadeContainerTypes'
import * as s from './CrossFadeContainer.module.scss'

const cn = classNames.bind(s)

const CrossFadeContainer = ({
  itemsDesktop,
  desktopChildren,
  itemsMobile,
  numberOfItems,
  onChange,
}: CrossFadeContainerProps) => {
  const isDesktop = useIsDesktop()
  const [ready, setReady] = useState<boolean>(false)

  useLayoutEffect(() => {
    if (!ready) setReady(true)
  }, [ready])

  if (!isDesktop) {
    return (
      <div className={cn({ loading: !ready })} style={{ '--number-of-items': numberOfItems } as React.CSSProperties}>
        {itemsMobile.map((i, index) => (
          <TwoColumn data={i} key={index}>
            {i.children}
          </TwoColumn>
        ))}
      </div>
    )
  }

  if (desktopChildren)
    return (
      <StickyItems items={itemsDesktop} onChange={onChange}>
        {desktopChildren}
      </StickyItems>
    )

  return <StickyItems items={itemsDesktop} onChange={onChange} />
}

export default CrossFadeContainer
