import React, { useMemo } from 'react'
import classNames from 'classnames/bind'
import { easePolyIn, easePolyOut } from 'd3-ease'
import { RichText } from 'prismic-reactjs'
import { withArtDirection, GatsbyImage } from 'gatsby-plugin-image'

import useParallaxScroll from 'lib/useParallaxScroll'
import StickyItems from 'components/core/StickyItems'
import ScrollParallax from 'components/core/ScrollParallax'
import { PopupImitation } from 'components/ui/Popup'

import { ExampleImageBackgroundTypes } from './ExampleImageBackgroundTypes'
import * as s from './ExampleImageBackground.module.scss'
import serializer from './serializer'

const cn = classNames.bind(s)
const mediumBreakpoint = parseInt(s.breakpointMedium)

const ExampleImageBackground = ({ data, items }: ExampleImageBackgroundTypes) => {
  const scrollY = useParallaxScroll()

  const sanitized = useMemo(
    () =>
      items.map((item, index) => ({
        layout: data.layout,
        keyframes: { y: ['30vh', '0vh', '-30vh'] },
        times: { y: [0, 0.5, 1] },
        startOffset: index === 0 ? '-40vh' : `${index * 1.7 * 100}vh`,
        stopOffset: `${index * 1.7 * 100 + 100}vh`,
        fixedFadeInOnText: true,
        image: (
          <div className={cn('imageContainer')}>
            <GatsbyImage
              loading='eager'
              className={cn('image')}
              image={withArtDirection(item.image.gatsbyImageData, [
                {
                  media: `(max-width: ${mediumBreakpoint}px)`,
                  image: item.image.thumbnails
                    ? item.image.thumbnails.mobile.gatsbyImageData
                    : item.image.gatsbyImageData,
                },
              ])}
              alt={item.image.alt || ''}
            />
          </div>
        ),
        text: (
          <ScrollParallax
            motionValue={scrollY}
            keyframes={{
              rotate: index % 2 === 0 ? ['-3deg', '6deg', '15deg'] : ['3deg', '-6deg', '-15deg'],
            }}
            times={{ rotate: [0.1, 0.5, 0.95] }}
            startOffset={`${index * 1.7 * 100 - 90}vh`}
            stopOffset={`${index * 1.7 * 100 - 20}vh`}
            ease={{ y: [easePolyOut.exponent(1.15), easePolyIn.exponent(1.15)] }}
          >
            <PopupImitation>
              <RichText render={item.text.raw} htmlSerializer={serializer} />
            </PopupImitation>
          </ScrollParallax>
        ),
      })),
    [items, data.layout, scrollY],
  )

  return <StickyItems items={sanitized} fullscreenImage />
}

export default ExampleImageBackground
