// extracted by mini-css-extract-plugin
export var colorBlack = "#000";
export var colorGray = "gray";
export var colorDarkGray = "#262626";
export var colorLightGray = "#e5e5e5";
export var colorWhite = "#fff";
export var colorElectricBlue = "#0000fe";
export var colorPastelBlue = "#bbe5fe";
export var colorCandyPink = "#f9709a";
export var colorPastelPink = "#ffcdd2";
export var colorDefaultLime = "#cef564";
export var colorPastelLime = "#eeffbe";
export var colorBlueLagoon = "#9cf0e1";
export var colorPastelLagoon = "#ceffec";
export var colorDefaultLavender = "#8738f6";
export var colorPastelLavender = "#eadcff";
export var colorBrightYellow = "#ffe500";
export var colorDefaultGreen = "#12b968";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1024px";
export var breakpointLarge = "1440px";
export var colCount = "6";
export var colWidth = "16.6666666667%";
export var container = "WorldMap-module--container--2Zwwt";
export var title = "WorldMap-module--title--3hibC";
export var highlight = "WorldMap-module--highlight--23x2Z";
export var mapWrapper = "WorldMap-module--mapWrapper--39O6K";
export var worldMapSvg = "WorldMap-module--worldMapSvg--14ejz";
export var hotspotWrapper = "WorldMap-module--hotspotWrapper--1eQLo";
export var hotspot = "WorldMap-module--hotspot--3bXbq";
export var hotspotIcon = "WorldMap-module--hotspotIcon--3323m";
export var blob = "WorldMap-module--blob--1V7NM";
export var blobIcon = "WorldMap-module--blobIcon--a_urB";
export var popup = "WorldMap-module--popup--3FWz8";
export var popupTitle = "WorldMap-module--popupTitle--t0StZ";