import React from 'react'
import classNames from 'classnames/bind'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { easeQuadOut, easeQuadIn } from 'd3-ease'

import useIsDesktop from 'lib/useIsDesktop'
import useParallaxScroll from 'lib/useParallaxScroll'
import { Row, Column } from 'components/core/Grid'
import ScrollParallax from 'components/core/ScrollParallax'
import { CircularHeading2 } from 'components/ui/Text'
import Container from 'components/core/Container'

import * as s from './ShortExplanationIcons.module.scss'
import { ShortExplanationIconsProps } from './ShortExplanationIconsTypes'

const cn = classNames.bind(s)

const ShortExplanationIcons = ({ data }: ShortExplanationIconsProps) => {
  const image = data.image ? getImage(data.image.gatsbyImageData) : null
  const icons = new Array(16).fill(image)
  const parallaxScroll = useParallaxScroll()
  const isDesktop = useIsDesktop()

  return (
    <div className={cn('container')}>
      <Container>
        <Row>
          <Column initial={0} medium={1} />
          <Column initial={6} medium={4}>
            {data.text && (
              <CircularHeading2 className={cn('title')} as='p'>
                {data.text.text}
              </CircularHeading2>
            )}
          </Column>
          <Column initial={0} medium={1} />
        </Row>
      </Container>
      {image && (
        <ScrollParallax
          key={`parallax-icons-${isDesktop}`}
          keyframes={isDesktop ? { y: ['27vh', '0vh', '-20vh'] } : { y: ['50vw', '0vw', '-65vw'] }}
          startOffset={isDesktop ? '15vh' : '0vh'}
          stopOffset={isDesktop ? '60vh' : '0vh'}
          absolute
          motionValue={parallaxScroll}
          ease={{ y: [easeQuadIn, easeQuadOut] }}
        >
          <div className={cn('icons')}>
            {icons.map((icon, index) => (
              <GatsbyImage
                objectFit='contain'
                className={cn('icon')}
                image={icon}
                {...(index === 0 && data?.image?.alt
                  ? {
                      alt: data?.image?.alt,
                    }
                  : {
                      alt: '',
                      role: 'presentation',
                    })}
                key={index}
              />
            ))}
          </div>
        </ScrollParallax>
      )}
    </div>
  )
}

export default ShortExplanationIcons
