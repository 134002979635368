// extracted by mini-css-extract-plugin
export var colorBlack = "#000";
export var colorGray = "gray";
export var colorDarkGray = "#262626";
export var colorLightGray = "#e5e5e5";
export var colorWhite = "#fff";
export var colorElectricBlue = "#0000fe";
export var colorPastelBlue = "#bbe5fe";
export var colorCandyPink = "#f9709a";
export var colorPastelPink = "#ffcdd2";
export var colorDefaultLime = "#cef564";
export var colorPastelLime = "#eeffbe";
export var colorBlueLagoon = "#9cf0e1";
export var colorPastelLagoon = "#ceffec";
export var colorDefaultLavender = "#8738f6";
export var colorPastelLavender = "#eadcff";
export var colorBrightYellow = "#ffe500";
export var colorDefaultGreen = "#12b968";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1024px";
export var breakpointLarge = "1440px";
export var colCount = "6";
export var colWidth = "16.6666666667%";
export var container = "SparkSentenceTwoColumn-module--container--167r5";
export var ready = "SparkSentenceTwoColumn-module--ready--C2ZDc";
export var content = "SparkSentenceTwoColumn-module--content--26APz";
export var text = "SparkSentenceTwoColumn-module--text--KiDSX";
export var image = "SparkSentenceTwoColumn-module--image--29u_y";
export var textContent = "SparkSentenceTwoColumn-module--textContent--BQhwu";
export var rightAlignImage = "SparkSentenceTwoColumn-module--rightAlignImage--3n1uJ";
export var bg = "SparkSentenceTwoColumn-module--bg--2ejyZ";