// extracted by mini-css-extract-plugin
export var colorBlack = "#000";
export var colorGray = "gray";
export var colorDarkGray = "#262626";
export var colorLightGray = "#e5e5e5";
export var colorWhite = "#fff";
export var colorElectricBlue = "#0000fe";
export var colorPastelBlue = "#bbe5fe";
export var colorCandyPink = "#f9709a";
export var colorPastelPink = "#ffcdd2";
export var colorDefaultLime = "#cef564";
export var colorPastelLime = "#eeffbe";
export var colorBlueLagoon = "#9cf0e1";
export var colorPastelLagoon = "#ceffec";
export var colorDefaultLavender = "#8738f6";
export var colorPastelLavender = "#eadcff";
export var colorBrightYellow = "#ffe500";
export var colorDefaultGreen = "#12b968";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1024px";
export var breakpointLarge = "1440px";
export var colCount = "6";
export var colWidth = "16.6666666667%";
export var wrapper = "Reactions-module--wrapper--2OgT_";
export var button = "Reactions-module--button--3ER2M";
export var iconContainer = "Reactions-module--iconContainer--3rFx7";
export var lottie = "Reactions-module--lottie--2Jpt0";
export var icon = "Reactions-module--icon--1kfX5";
export var storedReaction = "Reactions-module--storedReaction--1hiWj";
export var active = "Reactions-module--active--3n9sB";
export var countContainer = "Reactions-module--countContainer--3Zt1K";
export var count = "Reactions-module--count--1BSS_";
export var relative = "Reactions-module--relative--2lBYo";
export var tooltip = "Reactions-module--tooltip--VfeKD";
export var floating = "Reactions-module--floating--1cdmk";
export var plus = "Reactions-module--plus--1thsi";