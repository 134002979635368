import React, { useRef, useState, useLayoutEffect, useMemo, useCallback, useEffect } from 'react'
import { useFrame, useThree, ThreeEvent } from '@react-three/fiber'
import {
  Texture,
  sRGBEncoding,
  NearestFilter,
  MathUtils,
  Mesh,
  Group,
  BackSide,
  BufferGeometry,
  BufferAttribute,
} from 'three'
import { Line2 } from 'three/examples/jsm/lines/Line2.js'
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial.js'
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry.js'
// @ts-ignore
import { mergeBufferGeometries } from 'three/examples/jsm/utils/BufferGeometryUtils.js'

import { GlobeMeshProps } from '../Data3DGlobe/Data3DGlobeTypes'

export default function GlobeMesh({
  textureSource,
  countries = '',
  lat = 0,
  lng = 0,
  color = 'deeppink',
  fullWindowParallax,
  showCountries,
  bg = '#f00',
  ...props
}: GlobeMeshProps) {
  const ref = useRef<Mesh>()
  const group = useRef<Group>()
  const mouse = useRef({ x: 0, y: 0, over: false }).current
  const [map, setMap] = useState<Texture>()
  const { gl, invalidate, viewport, size } = useThree()
  const MAX_TEXTURE_SIZE = Math.min(1024 * 3, gl.capabilities.maxTextureSize)
  const { canvas, ctx, image, texture } = useMemo(() => {
    // paint SVG onto a 2D canvas
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    const image = new Image()

    // Create webgl texture from the canvas
    const texture = new Texture(canvas)
    texture.encoding = sRGBEncoding
    texture.anisotropy = gl.capabilities.getMaxAnisotropy()
    texture.magFilter = NearestFilter
    texture.minFilter = NearestFilter

    return { canvas, ctx, image, texture }
  }, [gl])

  // Generate Texture from SVG
  useLayoutEffect(() => {
    if (!showCountries) return
    const svg = textureSource.current.querySelector('svg')

    // add styles to SVG markup to highlight countries
    const inlineStyle = document.createElement('style')
    inlineStyle.textContent = `
      .country{
        opacity:1;
        fill:#fff;
        fill-opacity:1;
        fill-rule:evenodd;
        stroke:none;
        stroke: #fff;
        stroke-width: .14;
      }
      ${countries
        .split(',')
        .map(country => `.${country.replace(/\s+/g, '')} { fill: ${color} !important; stroke:${color} !important  }`)
        .join(' \n')}
    `
    svg.appendChild(inlineStyle)

    // generate dataURI from SVG
    const svgData = new XMLSerializer().serializeToString(svg)
    const base64 = window.btoa(unescape(encodeURIComponent(svgData)))
    const src = 'data:image/svg+xml;base64,' + base64

    // remove inlines styles for next generation
    inlineStyle.remove()

    image.onload = function () {
      canvas.width = MAX_TEXTURE_SIZE
      canvas.height = MAX_TEXTURE_SIZE * 0.5
      ctx?.drawImage(image, 0, 0, image.width, image.height, 0, 0, canvas.width, canvas.height)
      texture.needsUpdate = true
      setMap(texture)
      invalidate()
    }

    // load dataURI
    image.src = src
  }, [gl, invalidate, canvas, ctx, image, texture, countries, textureSource, MAX_TEXTURE_SIZE, color, showCountries])

  // rotate globe towards lat/lng
  useFrame(() => {
    if (!ref.current || !group.current) return

    const targetRotY = -Math.PI / 2 - MathUtils.degToRad(lng) - mouse.x
    const targetRotX = MathUtils.degToRad(lat) + mouse.y * 0.5
    ref.current.rotation.y = MathUtils.lerp(ref.current.rotation.y, targetRotY, 0.08)
    ref.current.rotation.x = MathUtils.lerp(ref.current.rotation.x, targetRotX, 0.08)

    // scale on hover
    group.current.scale.setScalar(MathUtils.lerp(group.current.scale.x, mouse.over ? 1.1 : 1, 0.08))

    // only render a frame if we need to
    const delta = Math.abs(ref.current.rotation.y - targetRotY) + Math.abs(ref.current.rotation.x - targetRotX)
    if (delta > 0.01) invalidate()
  })

  const onPointerEnter = useCallback(() => {
    mouse.over = true
    invalidate()
  }, [mouse, invalidate])

  const onPointerMove = useCallback(
    (e: ThreeEvent<PointerEvent>) => {
      mouse.x = e.spaceX
      mouse.y = e.spaceY
      mouse.over = true
      invalidate()
    },
    [mouse, invalidate],
  )

  const onPointerLeave = useCallback(() => {
    mouse.x = 0
    mouse.y = 0
    mouse.over = false
    invalidate()
  }, [mouse, invalidate])

  const onMouseMove = useCallback(
    (e: MouseEvent) => {
      mouse.over = true
      mouse.x = ((e.clientX - size.width * 0.5) / size.width) * 0.5 // -1 to 1
      mouse.y = ((e.clientY - size.height * 0.5) / size.height) * -0.5 // -1 to 1
      invalidate()
    },
    [mouse, invalidate, size],
  )

  // bind to window and body? otherwise we use the threejs raycaster
  useEffect(() => {
    if (fullWindowParallax) {
      window.addEventListener('mousemove', onMouseMove)
      document.body.addEventListener('mouseleave', onPointerLeave)
      document.body.addEventListener('mouseenter', onPointerEnter)
      return () => {
        window.removeEventListener('mousemove', onMouseMove)
        document.body.removeEventListener('mouseleave', onPointerLeave)
        document.body.removeEventListener('mouseenter', onPointerEnter)
      }
    }
  }, [onMouseMove, onPointerLeave, onPointerEnter, fullWindowParallax])

  // Calculate globe radius based on aspect
  const radius = Math.min(viewport.width * 0.33, viewport.height * 0.33)

  const { verticalLine, horizontalLines } = useMemo(() => {
    const segments = 128
    const vRings = 6
    const rings = []

    // generate all vertical rings
    for (let v = 0; v < vRings; v++) {
      const ring = []
      for (let i = 0; i < segments; i++) {
        const x = Math.sin(((Math.PI * 2) / (segments - 1)) * i) * radius
        const y = Math.cos(((Math.PI * 2) / (segments - 1)) * i) * radius
        ring.push(x, y, 0)
      }
      const geom = new BufferGeometry()
      geom.setAttribute('position', new BufferAttribute(new Float32Array(ring), 3))
      geom.rotateY((Math.PI / vRings) * v)
      rings.push(geom)
    }
    const mg = mergeBufferGeometries(rings)
    const verticalGeom = new LineGeometry().setPositions(new Float32Array(mg.attributes.position.array))

    // generate horizontal ring
    function getHRingVertices(y: number) {
      const ring = []
      for (let i = 0; i < segments; i++) {
        // https://math.stackexchange.com/questions/642176/finding-the-radius-of-a-circle-that-intersects-a-sphere
        const r = Math.sqrt(Math.pow(1, 2) - Math.pow(y, 2)) * radius
        const x = Math.sin(((Math.PI * 2) / (segments - 1)) * i) * r
        const z = Math.cos(((Math.PI * 2) / (segments - 1)) * i) * r
        ring.push(x, y * radius, z)
      }
      const geom = new BufferGeometry()
      geom.setAttribute('position', new BufferAttribute(new Float32Array(ring), 3))
      return new Float32Array(geom.attributes.position.array)
    }

    const mat = new LineMaterial({ color: 0xffffff, linewidth: 0.0011 })

    return {
      verticalLine: new Line2(verticalGeom, mat),
      horizontalLines: [
        new Line2(new LineGeometry().setPositions(getHRingVertices(0.965)), mat),
        new Line2(new LineGeometry().setPositions(getHRingVertices(0.87)), mat),
        new Line2(new LineGeometry().setPositions(getHRingVertices(0.7)), mat),
        new Line2(new LineGeometry().setPositions(getHRingVertices(0.255)), mat),
        new Line2(new LineGeometry().setPositions(getHRingVertices(0)), mat),
        new Line2(new LineGeometry().setPositions(getHRingVertices(-0.255)), mat),
        new Line2(new LineGeometry().setPositions(getHRingVertices(-0.5)), mat),
        new Line2(new LineGeometry().setPositions(getHRingVertices(-0.7)), mat),
        new Line2(new LineGeometry().setPositions(getHRingVertices(-0.87)), mat),
        new Line2(new LineGeometry().setPositions(getHRingVertices(-0.965)), mat),
      ],
    }
  }, [radius])

  // bind raycast events if needed
  const events = fullWindowParallax
    ? {}
    : {
        onPointerEnter: onPointerEnter,
        onPointerMove: onPointerMove,
        onPointerLeave: onPointerLeave,
      }

  return (
    <>
      <group ref={group} rotation-z={Math.PI * -0.1} {...props}>
        <mesh ref={ref}>
          {/* Create inner globe to mask lines on other side of globe */}
          <mesh scale={0.997}>
            <sphereGeometry args={[radius, 128, 128]} />
            <meshBasicMaterial color={bg} />
          </mesh>
          {/* Sphere with country texture - always render on top (no depth test) */}
          {map && (
            <mesh>
              <sphereGeometry args={[radius, 128, 128]} />
              <meshBasicMaterial color='white' map={map} transparent depthTest={false} />
            </mesh>
          )}
          {/* Render all lines as separate geometries */}
          <primitive object={verticalLine} />
          <primitive object={horizontalLines[0]} />
          <primitive object={horizontalLines[1]} />
          <primitive object={horizontalLines[2]} />
          <primitive object={horizontalLines[3]} />
          <primitive object={horizontalLines[4]} />
          <primitive object={horizontalLines[5]} />
          <primitive object={horizontalLines[5]} />
          <primitive object={horizontalLines[6]} />
          <primitive object={horizontalLines[7]} />
          <primitive object={horizontalLines[8]} />
          <primitive object={horizontalLines[9]} />
        </mesh>
        {/* Render outer sroked ring - raycast here since it's less work */}
        <mesh scale={1.001} {...events}>
          <sphereGeometry args={[radius, 128, 128]} />
          <meshBasicMaterial color='white' transparent side={BackSide} />
        </mesh>
      </group>
    </>
  )
}
