import React, { forwardRef, useEffect, useRef, useState } from 'react'
import classNames from 'classnames/bind'
import { motion, useMotionValue } from 'framer-motion'

import useIsDesktop from 'lib/useIsDesktop'
import Svg from 'assets/svg/revealer.react.svg'
import SvgMobile from 'assets/svg/revealer-mobile.react.svg'
import ViewportEnter from 'components/core/ViewportEnter'

import { RevealerTypes } from './ExampleDragAndRevealTypes'
import * as s from './ExampleDragAndReveal.module.scss'
const cn = classNames.bind(s)

// eslint-disable-next-line react/display-name
const Revealer = forwardRef<HTMLDivElement, RevealerTypes>(({ offset, updateProgress }, ref) => {
  const rect = useRef()
  const x = useMotionValue(0)
  const [isInView, setIsInView] = useState(false)
  const isSet = useRef(false)

  const isDesktop = useIsDesktop()

  const onDrag = (e: DragEvent) => {
    rect.current = ref.current.getBoundingClientRect()
    const x = Math.max(Math.min(e.clientX - rect.current.x, rect.current.width), 0) // TO DO (% instead of px)
    const progress = Math.max(Math.min((e.clientX - rect.current.x) / rect.current.width, 1), 0)
    updateProgress(progress, x)
  }

  useEffect(() => {
    if (!isInView || isSet.current) return
    isSet.current = true
    const progress = offset * 0.01
    updateProgress(progress, progress * ref.current.getBoundingClientRect().width)
  }, [isInView, offset, ref, updateProgress])

  return (
    <ViewportEnter onEnter={() => setIsInView(true)}>
      <div className={cn('revealer', { isInView })} aria-hidden='true'>
        <motion.div drag='x' dragConstraints={ref} dragMomentum={false} dragElastic={0} onDrag={onDrag} style={{ x }}>
          {isDesktop ? <Svg /> : <SvgMobile />}
        </motion.div>
      </div>
    </ViewportEnter>
  )
})

export default Revealer
