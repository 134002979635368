import React, { useState, useEffect } from 'react'
import classNames from 'classnames/bind'

import useParallaxScroll from 'lib/useParallaxScroll'
import { ParallaxSticky } from 'components/core/ScrollParallax'

import { StickyItemsTypes } from './StickyItemsTypes'
import Item from './Item'
import * as s from './StickyItems.module.scss'

const cn = classNames.bind(s)

const StickyItems = ({ items, onChange, fullscreenImage = false, children }: StickyItemsTypes) => {
  const scrollY = useParallaxScroll()
  const [active, setActive] = useState(0)

  useEffect(() => {
    if (onChange) onChange(active)
  }, [active, onChange])

  return (
    <div className={cn('container')} style={{ '--number-of-items': items.length } as React.CSSProperties}>
      <ParallaxSticky className={cn('stickyContainer')}>
        {fullscreenImage &&
          items.map((i, index) => (
            <div
              style={
                {
                  opacity: active >= index ? 1 : 0,
                } as React.CSSProperties
              }
              key={`full-screen-image-${index}`}
              className={cn('image', { animate: index !== 0 })}
            >
              {i.image}
            </div>
          ))}

        {items.map(
          ({ layout, image, animation, text, keyframes, times, startOffset, stopOffset, fixedFadeInOnText }, index) => (
            <Item
              keyframes={keyframes || { y: ['30vh', '0vh', '-30vh'], opacity: [0, 1, 1, 0] }}
              times={times || { y: [0, 0.5, 1], opacity: [0, 0.25, 0.75, 1] }}
              startOffset={startOffset || (index === 0 ? '40vh' : `${index * 1.7 * 100}vh`)}
              stopOffset={stopOffset || (index === 0 ? '100vh' : `${index * 1.7 * 100 + 100}vh`)}
              index={index}
              layout={layout}
              text={text}
              scrollY={scrollY}
              key={`item-${index}`}
              fixedFadeInOnText={fixedFadeInOnText || false}
              animation={animation}
              onChange={n => setActive(n)}
              isActive={active === index}
              {...(!fullscreenImage && {
                image,
              })}
            />
          ),
        )}

        {children}
      </ParallaxSticky>
    </div>
  )
}

export default StickyItems
